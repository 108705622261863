var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading.center",value:(_vm.noCircle && _vm._loading),expression:"noCircle && _loading",modifiers:{"center":true}}],class:{
	avatar: true,
 	[_vm.size]: true,
 	[_vm.variant]: true,
 	noBackground: _vm.noBackground,
 	'no-circle': _vm.noCircle,
 	clickable: _vm.$listeners.click ? true : false
 },style:(_vm._hex ? `background-color: ${_vm._hex} !important;` : ''),on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{class:`avatar-content ${_vm.variant}`},[(_vm.icon)?_c('icon',{model:{value:(_vm.icon),callback:function ($$v) {_vm.icon=$$v},expression:"icon"}}):_vm._e(),_vm._v(" "),(_vm.text)?_c('div',{class:`avatar-text`},[_vm._v(_vm._s(_vm.text))]):_vm._e()],1),_vm._v(" "),(_vm.brightness != undefined)?_c('div',{staticClass:"overlay",style:(`background-color: ${this.overlayBackground}`)}):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(!_vm.noCircle && _vm._loading)?_c('div',{staticClass:"avatar-loader"}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }