var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phone-number-input"},[_c('icon-button',{attrs:{"icon":_vm.icons[_vm.user_phone.label],"show-carat":"","dropright":"","tooltip":_vm._f("titlecase")(_vm.user_phone.label),"hover":""}},[[_c('b-dropdown-item',{on:{"click":function($event){return _vm.handleLabelUpdate('home')}}},[_vm._v("Home")]),_vm._v(" "),_c('b-dropdown-item',{on:{"click":function($event){return _vm.handleLabelUpdate('mobile')}}},[_vm._v("Mobile")]),_vm._v(" "),_c('b-dropdown-item',{on:{"click":function($event){return _vm.handleLabelUpdate('work')}}},[_vm._v("Work")])]],2),_vm._v(" "),_c('vue-tel-input',{ref:"vue-tel-input",attrs:{"onlyCountries":_vm.$stayfunctions.isos(_vm.locales),"inputClasses":{ 'is-invalid': _vm.inValid },"wrapperClasses":{ 'is-invalid': _vm.inValid },"dropdown-options":{
					showDialCodeInSelection: true,
					showFlags: false
				},"enabledFlags":false,"enabled-country-code":"","placeholder":_vm.placeholder,"label":_vm.user_phone.label},on:{"validate":_vm.handleValidate,"country-changed":_vm.handleInput,"input":_vm.handleInput,"blur":function($event){return _vm.$emit('blur')},"enter":function($event){_vm.$emit('keyup', new _vm.keyboard_event('keyup', {
    				code: 'Enter',
    				key: 'Enter',
					charCode: 13,
					keyCode: 13,
				}))}},scopedSlots:_vm._u([{key:"arrow-icon",fn:function(){return [_c('icon',{attrs:{"value":"arrow-down"}})]},proxy:true}]),model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=$$v},expression:"phone_number"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }