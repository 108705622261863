import icons from 'images/icons/icons.json'

const PATH_REGEX = /(?<=d=['"])[A-Za-z\- 0-9.]+(?=['"] ?\/>)/g  // Used to strip the paths out of the SVG


export default {

	icons: function(name){
		let icon = icons.find(icon => icon.name == name)
		if(!icon) console.warn(`No icon was found with the name '${name}'. Ensure you have added this icon to the application and built the icon digest in the command line.`)
		return icon
	},

	paths: function(name){
		let icon = icons.find(icon => icon.name == name)
		if(!icon) console.warn(`No icon was found with the name '${name}'. Ensure you have added this icon to the application and built the icon digest in the command line.`)
		return [...icon.svg.matchAll(PATH_REGEX)]
	},

	all: function(){
		return icons
	}

}