<template>
	<div class="icon-viewer d-flex flex-row flex-wrap bg-white">

		<!-- <div class="details">Controls to impact icon settings</div> -->

		<div class="icon-viewer-icon m-3" v-for="icon in _icons" :key="icon.name">
			<label>{{icon.name}}</label>
			<icon :value="icon.name" :variant="variant" :class="classes" :size="size" :background-variant="backgroundVariant" />
		</div>

	</div>
</template>

<script>
	import Icons from 'images/icons/icons.json'
	import Icon from 'common/icon'

	export default {
		name: 'icon-viewer',
		components: { Icon },
		data(){
			return {
				icons: Icons,
				variant: 'dark',
				classes: '',
				size: 'md',
				backgroundVariant: null
			}
		},
		computed: {
			_icons(){
				return this.icons.sort((a, b) => (a.name > b.nname) ? 1 : -1)
			}
		}
	}
</script>