<template>
	<span :class="`dot${pulse ? ' pulse' : ''}`" :style="{backgroundColor: hex || $colors[variant]}"></span>
</template>

<script>
	import Colors from 'mixins/colors'
	export default {
		name: 'dot',
		props: {
			variant: {
				type: String,
				default: "secondary"
			},
			hex: String,
			pulse: Boolean
		},
		mixins: [Colors]
	}
</script>

<style lang="scss">
	.dot {
		height: 0.5rem;
		width: 0.5rem;
		min-height:  0.5rem;
		min-width: 0.5rem;
		display: inline-block;
		border-radius: 50%;

		&.pulse {
			animation: dot-pulse 2s infinite;
		}

	}

	@keyframes dot-pulse {
		0% {
			opacity:  1;
		}

		100% {
			opacity:  0.8;
		}
	}
</style>