<template>
	<div class="navbar p-5 w-100 d-flex justify-content-between align-items-center">
		<a href="/" class="logo">stay</a>
		<a href="/user/login" id="login_button">login</a>
	</div>
</template>

<script>
	export default {
		name: 'home-navigation'
	}
</script>

<style lang="scss">
	@import 'stylesheets/colors';
	@import 'stylesheets/variables';

	.navbar {
		color: $dark-color;

		.logo {
			font-size: 3em;
			color: $dark-color;
			font-weight: 200;
			text-decoration: none;
			font-family: $primary-font;

			&:visited {
				color: $dark-color;
			}
		}

		#login_button {
			color: $dark-color;
			background-color: $light-color;
			border: 0.5px solid $dark-color;
			border-radius: 15px;
			padding: 5px 20px 5px 20px;
			text-decoration: none;
		}
	}

</style>