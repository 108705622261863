<template>
	<span :class="`icon${_variant ? ` ${_variant}` : ''} ${size}`">

		<span v-html="stayIcons.icons(_value).svg" />

		<!-- <span v-if="is_bool && bool" v-html="featherIcons.icons['check'].toSvg({'stroke-width': 0.75})" />
		<span v-if="is_bool && !bool" v-html="featherIcons.icons['x'].toSvg({'stroke-width': 0.75})" />
		<i v-if="!is_bool && _framework=='line-awesome'" :class="`las la-${value}`" :style="`${backgroundVariant ? '-webkit-text-stroke: 0.04rem ' + $staycolors[backgroundVariant] : ''}`"></i>
		<span v-if="!is_bool && _framework=='feather'" v-html="featherIcons.icons[value].toSvg({'stroke-width': 0.75})" />
		<span v-if="!is_bool && _framework=='stay'" v-html="stayIcons.icons[value]" /> -->
	</span>
</template>

<script>
	//import FeatherIcons from 'vendor/feather'
	import StayIcons from 'plugins/stay-icons'
	export default {
		name: 'icon',
		props: {
			value: {
				type: String,
				default: 'circle'
			},
			//framework: String,	// Right now this is one of "feather" or "line-awesome"
			variant: String,
			size: {
				type: String,
				default: "md"
			},
			bool: {
				type: Boolean,
				default: null
			},
			backgroundVariant: String 	// We use this for the line-awesome stroke hack
										// Set this to the background color the font is sitting
										// in front of!
		},
		data(){
			return {
				//featherIcons: FeatherIcons,
				stayIcons: StayIcons
			}
		},
		computed: {
			// _framework(){

			// 	// If the framework is provided we should just force that here, otherwise we should check to see if the icon
			// 	// exists within each of the framworks first:
			// 	if(this.framework) return this.framework
			// 	if(FeatherIcons.icons[this.value]) return 'feather'
			// 	return 'line-awesome'
			// },
			is_bool(){
				return this.bool == true || this.bool == false
			},
			_variant(){
				return this.is_bool ? (this.bool ? 'success' : 'danger') : this.variant 
			},
			_value(){
				return this.is_bool ? (this.bool ? 'check' : 'x') : this.value
			}
		}
	}
</script>

<style lang="scss">
	@import 'stylesheets/colors';
	//@import 'stylesheets/line-awesome';
	@import 'stylesheets/sizes';

	.icon {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		//width: auto;
		@include colors;
		@include svg-fill-colors;
		//@include width-sizes;
		//@include height-sizes;
		//@include line-height-sizes;
		//@include min-width-sizes;
		@include font-sizes(1.2);
		@include stroke-sizes;


		span {
			//display: block;
			//width: auto;
			min-width: 1em;
			//height: 1em;

			svg {
				height: 1em;
				width: 1em;
			}
		}


		&.dark i {
			-webkit-text-stroke: 0.04rem white;
		}

		&.light i {
			-webkit-text-stroke: 0.04rem $dark-color;
		}


	}

</style>