<template>
	<div :class="`error-icon ${size}`">
		<div class="attention">!</div>
	</div>
</template>

<script>
	export default {
		name: 'error-icon',
		props: {
			size: {
				type: String,
				default: "md"
			}
		},
		methods: {
			animate(){
				// Reset the animation
			}
		}
	}
</script>

<style lang="scss">

	@import 'stylesheets/sizes';

	.error-icon {
		@include width-sizes;
		@include height-sizes;

		// .attention is handled on global.scss
	}

</style>