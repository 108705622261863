<template>
	<div></div>
</template>

<script>
	export default {
		props: {
			message: {
				type: String | Array,
				default: "Are you sure you want to continue?"
			},
			okTitle: {
				type: String,
				default: "Ok"
			},
			okVariant: {
				type: String,
				default: "primary"
			},
			cancelTitle: {
				type: String,
				default: "Cancel"
			}
		},
		mounted(){
			this.$bvModal.msgBoxConfirm(this.message, {...{id: 'stay-confirm-dialog'}, ...this.$props}).then(response=>{
				this.$emit('confirm', response)
			})
		}
	}
</script>