// Provides our standard color palette to Vue components
// as a series of functions and objects:

const Colors = {
	primary: "#007bff",
	secondary: "#ACACAC",
	light: "#F5F5F5",
	dark: "#30323D",
	info: "#089FDA",
	success: "#3CB371",
	warn: "#FFE066",
	danger: "#BB4430",
	white: "#FFFFFF"
}

const TextVariant = {
	primary: 'white',
	secondary: 'white',
	light: 'dark',
	dark: 'white',
	info: 'white',
	success: 'white',
	warn: 'dark',
	danger: 'white',
	white: 'dark'
}

const TextHex = {
	primary: Colors[TextVariant.primary],
	secondary: Colors[TextVariant.secondary],
	light: Colors[TextVariant.light],
	dark: Colors[TextVariant.dark],
	info: Colors[TextVariant.info],
	success: Colors[TextVariant.success],
	warn: Colors[TextVariant.warn],
	danger: Colors[TextVariant.danger],
	white: Colors[TextVariant.white]
}


const hexToRGBA = function(h, a){
	let r = 0, g = 0, b = 0;
	// 3 digits
	if (h.length == 4) {
		r = "0x" + h[1] + h[1];
		g = "0x" + h[2] + h[2];
		b = "0x" + h[3] + h[3];
	// 6 digits
	} else if (h.length == 7) {
		r = "0x" + h[1] + h[2];
		g = "0x" + h[3] + h[4];
		b = "0x" + h[5] + h[6];
	}
	return "rgb("+ +r + "," + +g + "," + +b + "," + +a +")";
}


const lightenDarken = function(col, amt){
	var usePound = false;
	if (col[0] == "#") {
	  col = col.slice(1);
	  usePound = true;
	}
	var num = parseInt(col,16);
	var r = (num >> 16) + amt;
	if (r > 255) r = 255;
	else if  (r < 0) r = 0;
	var b = ((num >> 8) & 0x00FF) + amt;
	if (b > 255) b = 255;
	else if  (b < 0) b = 0;
	var g = (num & 0x0000FF) + amt;
	if (g > 255) g = 255;
	else if (g < 0) g = 0;
	return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
}


export default {

	computed: {

		$colors(){
			return {
				...{
					all: Colors,
					rgb: (variant) => hexToRGBA(Colors[variant], 1),
					brightness: (variant, adjustment) => lightenDarken(hexToRGBA(Colors[variant]), adjustment),
					text_hex: TextHex,
					text_variant: TextVariant
				},
				...Colors
			}
		}

	}

}

export { Colors, TextVariant, TextHex }