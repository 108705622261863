// Root mixin for all Organizations-specific controller
// views and their corresponding pack files.

import Common from './index'
import Navigation from '../../home/home_navigation';
import HomeFooter from '../../home/home_footer';
import "stylesheets/main" // Provides some CSS that we put together for the marketing site

export default {

	components: { Navigation, HomeFooter },
	mixins: [Common],

	mounted(){
		console.log("H O M E")
	}


}