<template>
	<b-modal id="phone-number-modal" :visible="visible" title="My Phone Numbers" :ok-disabled="!user_phone.valid" ok-title="Save" @ok="handleOk">
		<phone-number-input v-model="user_phone" placeholder="Add a phone number" class="mb-3" />

		<b-list-group>
			<b-list-group-item class="p-0" v-for="(phone, index) in user_phones" :key="phone.id">
				<entity-card class="p-3"  v-model="user_phones[index]" @click="handleSelect(phone)" />
			</b-list-group-item>
		</b-list-group>
	</b-modal>
</template>

<script>
	import {BListGroup, BListGroupItem} from 'bootstrap-vue'
	import PhoneNumberInput from 'common/phone_number_input'
	import Icon from 'common/icon'
	import EntityCard from 'entity/card'

	export default {
		name: 'phone-number-modal',
		components: { BListGroup, BListGroupItem, Icon, PhoneNumberInput, EntityCard},
		props: {
			visible: Boolean,
			user_phones: {
				type: Array,
				default: () => []
			}
		},
		data(){
			return {
				user_phone: {}
			}
		},
		methods: {
			handleSelect(phone){
				this.$emit('selected', phone)
			},

			handleOk(){
				this.$emit('new', this.user_phone)
			}
		}
	}
</script>