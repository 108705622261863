<template>
	<div class="minimized-modals-container">
		<transition-group name="slide-fade" tag="div">
			<minimized-modal v-for="modal in value" :key="modal.name" v-bind="value" @click="handleClick(modal)" />
		</transition-group>
	</div>
</template>.

<script>
	import MinimizedModal from 'common/minimized_modal'

	export default {
		name: 'minimized-modals-container',
		props: {
			value: Array
		},
		components: {MinimizedModal},
		methods: {
			handleClick(modal){
				this.$modals().maximize(modal)
			}
		}
	}
</script>

<style lang="scss">
	.minimized-modals-container {
		position: fixed;
		bottom: 0;
		width: 100%;
		padding-left: 3em;
		padding-right: 3em;
		display: flex;
		justify-content: end;
		flex-wrap: wrap;
	}
</style>