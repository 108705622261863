<template>
	<div class="stay-alerts-container">
		<transition-group name="fade" tag="div">
			<div :class="`stay-alert w-100 p-5 d-flex justify-content-between align-items-center ${alert.type}`" v-for="(alert, index) in alerts" :key="index">
				<span class="ml-1 flex-grow">{{alert.message}}</span>
				<button @click="clear(index)"><icon value="x" variant="light" /></button>
			</div>
		</transition-group>
	</div>
</template>

<script>
	import Icon from 'common/icon'
	export default {
		name: 'alerts',
		props: {
			alerts: Array
		},
		methods: {
			clear(index=-1){
				length = length || this.alerts.length
				if(index<0){
					this.alerts = []
				} else {
					this.alerts.splice(index, 1)
				}
			},
			alert(type, message){
				this.alerts.push({type: type, message: message})
				setTimeout(()=>{ this.clear(0) }, 4000)
			}
		},
		watch: {
			alerts(old, val){
				console.log("Alerts updated", val)
			}
		},
		components: {Icon}
	}
</script>

<style lang="scss">
	@import 'stylesheets/variables';
	@import 'stylesheets/colors';


	.stay-alerts-container {
		display: block;
		width: 100vw;
		position: fixed;
		top: 0;
		left: 0;
		z-index: $alert-index;
		pointer-events: none;

  			


		.stay-alert {
			@include background-colors;
			height: auto;
			// position: relative;
			// box-shadow: $box-shadow;
			pointer-events: auto;
			color: white;

			button {
				border: none;
				box-shadow: none !important;
				background-color: Transparent;

				:hover {
					background-color: rgba(0,0,0,0.1) !important;
				}
			}
  		}
	}

</style>