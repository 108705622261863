// Landing main application file
import Vue from 'vue/dist/vue.esm';
import VueI18n from 'vue-i18n';
import Home from 'mixins/root/home'


//import Alerts from 'common/alerts'
import DogBg from '../src/home/dog_bg'
import {BListGroup, BListGroupItem} from 'bootstrap-vue'

// Session Components
import NewSession from 'session/new_session'
import SessionTransfer from 'session/transfer'
import Authenticators from 'session/authenticators'

import SignUp from '../src/home/sign_up'
import IconViewer from '../src/home/icon_viewer'

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    data: {
    	csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      alerts: window.alerts,
      pageData: window.pageData,
      current_user: pageData.current_user
    },
    components: { 
      'dog-bg': DogBg,
      NewSession, SessionTransfer, BListGroupItem, BListGroup, Authenticators, IconViewer
    },
    mixins: [Home],
    modals: [SignUp],
    mounted(){
      console.log("STAY home.js mounted")
    }
  }).$mount('#app')
})