<template>
	<div class="session-transfer">
		<div class="create" v-if="!session_transfer.id">
			<b-button @click="handleCreateTransfer" block id="authenticate-button">Authenticate</b-button>
		</div>
		<div v-else class="redirect">
			<form method="POST" :action="`${session_transfer.host}/session_transfer/${session_transfer.id}/validate`" ref="transferForm">
				<input type="hidden" id="session_transfer[id]" name="session_transfer[id]" :value="session_transfer.id" />
				<input type="hidden" id="session_transfer[token]" name="session_transfer[token]" :value="session_transfer.token" />
				<input type="hidden" id="session_transfer[user_id]" name="session_transfer[user_id]" :value="$root.pageData.current_user.id" />
				<input type="hidden" id="authenticity_token" name="authenticity_token" :value="authenticity_token" />
			</form>
		</div>
	</div>
</template>

<script>
	import Http from 'mixins/http'

	export default {
		name: 'session-transfer',
		props: {
			value: Object // The session transfer object...
		},
		data(){
			return {
				session_transfer: {
					id: null,
					token: null,
					user_id: null,
					message: null,
					redirect: null,
					host: null,
					origin: null
				},
				authenticity_token: null
			}
		},
		methods: {
			async handleCreateTransfer(){
				try {
					this.$set(this.$data, 'session_transfer', await this.createTransfer())
					this.authenticity_token = await this.getAuthenticityToken()
					this.$nextTick(this.handleTransfer)
				} catch(err){
					throw(err)
				}
			},
			async createTransfer(){
				let res = await this.post("/session_transfer", {session_transfer: this.session_transfer}, {alert: false})
				return res.data.session_transfer
			},
			async getAuthenticityToken(){
				let res = await this.get(`${this.session_transfer.host}/session_transfer/${this.session_transfer.id}`, {}, {alert: false})
				return res.data.authenticity_token
			},
			handleTransfer(){
				this.$stayloader.enable(this.value.message)
				setTimeout(this.transfer, 2000)
			},
			transfer(){
				this.$refs.transferForm.submit()
			}
		},
		created(){
			this.$set(this.$data, 'session_transfer', {...this.session_transfer, ...this.value})
		},
		mixins: [Http]
	}

</script>

<style lang="scss">
	@import 'stylesheets/variables';

	.session-transfer {


	}

</style>