// JOBS
// Provides functions to create and manage subscriptions
// to active jobs

import {Entity} from 'entity'
import Http from 'mixins/http'
import JobProgress from 'common/job_progress'
import IconButton from 'common/icon_button'
import Icon from 'common/icon'
import {BDropdownItem} from 'bootstrap-vue'

const FakeWorker = {
	name: 'fake-worker',
	components: {IconButton, BDropdownItem},
	template: '<icon-button icon="zap" tooltip="Fake a job"><b-dropdown-item button @click="$parent.$jobs.fake(true)">With Success</b-dropdown-item><b-dropdown-item button variant="danger" @click="$parent.$jobs.fake(false)">With Failure</b-dropdown-item></icon-button>'
}


const FailedJob = {
	name: 'failed-job',
	template: '<span class="text-danger" v-if="jobs.failed.length > 0"><icon value="alert-triangle" variant="danger" class="mr-1" />{{jobs.failed[0].message}}</span>',
	components: { Icon },
	props: {
		jobs: Object
	}
}


const JobsMixin = {

	mixins: [Http],
	components: {JobProgress, FakeWorker, FailedJob},

	data(){
		return {
			jobs_entity: null,
			jobs_callbacks: [],
			jobs_subscriptions: []
		}
	},

	computed: {

		$jobs(){
			return {
				initialize: this.jobs_initialize,
				entity: this.jobs_entity,
				update: this.jobs_update,
				all: this.jobs_all,
				active: this.jobs_active,
				failed: this.jobs_failed,
				progress: this.jobs_progress,
				subscribe: this.jobs_subscribe,
				fake: this.jobs_fake,
				event_subscription: this.jobs_event_subscription
			}
		},

		jobs_event_subscription(){
			return this.jobs_entity && `${this.jobs_entity.plural}:${this.jobs_entity.id}:updated`
		},


		jobs_all(){
			return this.jobs_entity && (this.jobs_entity.jobs || this.jobs_entity.jobs_without_data) || []
		},

		jobs_active(){
			return this.jobs_all.filter(job => ['queued', 'working','retry'].includes(job.status))
		},

		jobs_failed(){
			return this.jobs_all.filter(job => ['dead'].includes(job.status))
		},

		jobs_progress(){
			return this.jobs_active.reduce((prev, curr) => prev + (curr.progress || 0), 0) / (this.jobs_active.length * 100)
		},

		// jobs_total(){
		// 	return this.jobs_active.reduce((prev, curr) => prev + (curr.total || 0), 0)
		// },

		// jobs_at(){
		// 	return this.jobs_active.reduce((prev, curr) => prev + (curr.at || 0), 0)
		// },

		// jobs_percent_complete(){
		// 	return this.jobs_total == 0 ? 0 : this.jobs_at / this.jobs_total * 100
		// }

	},

	methods: {

		jobs_initialize(entity){
			if(this.debug) console.log("Initializing a new jobs instance with entity", entity)
			this.jobs_entity = Entity(entity)
			this.jobs_subscribe()
		},

		jobs_update(entity){
			if(!Object.keys(entity).includes('jobs')) return // ignore any events that didn't pass the jobs object to the client
			entity = Entity(entity)
			this.$set(this, 'jobs_entity', entity)
			this.jobs_callbacks.forEach(callback => callback(entity))
		},

		jobs_subscribe(){
			this.$root.$on(this.jobs_event_subscription, this.jobs_update)
		},

		jobs_unsubsribe(){
			this.$root.$off(this.jobs_event_subscription, this.jobs_update)
		},


		// jobs_subscribe(object_or_event_string, on_update=()=>{}, options={}){

		// 	let event_string
		// 	let entity
		// 	if(typeof(object_or_event_string) == 'string'){
		// 		event_string = object_or_event_string
		// 	} else {
		// 		let entity = Entity(object_or_event_string)
		// 		event_string = `${entity.plural}:${entity.id}:updated`
		// 	}


		// 	if(this.jobs_subscriptions.some(subscription => subscription[0] == event_string && subscription[1] == on_update)) return // Don't allow the same subscription twice

		// 	// Add this subscription to the array of active subscriptionn
		// 	// so we don't try running it again...

		// 	this.jobs_subscriptions.push([event_string, on_update])
		// 	if(entity) this.$set(this, 'jobs_raw_response', entity)

		// 	// Set te subscription op the root event and call the 
		// 	// the on_update() handler whenever there is an event...

		// 	this.$root.$on(event_string, response => {
		// 		this.$set(this, 'jobs_raw_response', response)
		// 		return on_update(response)
		// 	})

		// },

		jobs_fake(succeed=true){
			this.post("/fake_workers", {entity_type: this.jobs_entity._strict_entity_type, entity_id: this.jobs_entity.id, succeed: succeed}).then(res => {
				console.log(res)
				this.$jobs.update(res.data.entity)
			})
		}

	}


}

export default JobsMixin