<template>
	<b-progress v-if="jobs.active.length > 0" :max="jobs.active.length * 100">
		<b-progress-bar v-for="(job, index) in jobs.active" :value="job.progress" :label="job.message" animated :variant="variants[index] || 'primary'" class="text-dark pl-1" />
	</b-progress>
</template>

<script>
	import {BProgress, BProgressBar} from 'bootstrap-vue'

	export default {
		name: 'job-progress',
		components: {BProgress, BProgressBar},
		props: {
			jobs: Object
		},
		data(){
			return {
				variants: ['primary','success','warn','info','secondary','danger','dark']
			}
		}
	}
</script>