<template>
	<div class="clipboard-button">
	    <icon-button 
	    	:icon="icon"
	    	:tooltip="tooltip"
	    	:size="size"
	    	@click="handleCopy"
	    />
		<div class="copy-status">
			<success-icon v-if="success" />
			<error-icon v-if="error" />
		</div>
	</div>
</template>

<script>
	import SuccessIcon from 'common/success_icon'
	import ErrorIcon from 'common/error_icon'
	import IconButton from 'common/icon_button'
	export default {
		props: {
			text: String,
			icon: {
				type: String,
				default: 'copy'
			},
			size: {
				type: String,
				default: "md"
			},
			tooltip: String
		},
		data(){
			return {
				success: false,
				error: false
			}
		},
		methods: {
			async handleCopy(){
				try {
					let res = await navigator.clipboard.writeText(this.text)
					return this.onCopy()
				} catch(err){
					console.error(err)
					return this.onError()
				}
			},
			onCopy(){
				this.success = true
				setTimeout(()=>{this.success = false}, 2000)
			},
			onError(){
				this.error = true
				setTimeout(()=>{this.error = false}, 2000)
			}
		},
		components: {SuccessIcon, ErrorIcon, IconButton}
	}
</script>

<style lang="scss">

	.clipboard-button { 
		display: inline-block;
		position: relative;

		.copy-status {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			padding: 0.4em;
			display: flex;
			justify-content: center;
			align-items: center;
			pointer-events: none;
		}
	}

</style>