<template>
	<button :class="`entity-card${$listeners.click ? ' hover' : ''}${selected ? ' selected' : ''}${flush ? ' flush' : ''}${card ? ' list-group' : ''}`" @click="handleClick" tabindex="0" :disabled="disabled || destroyed">

		<job-progress :jobs="$jobs" class="w-100" />
		<failed-job :jobs="$jobs" />

		<!--- VIEWED INDICATOR -->
		<div class="viewed-indicator" v-if="!noViewedIndicator && entity.viewed == false">
			<dot variant="info" />
		</div>

		<!-- ACTIONS -->
		<div class="entity-actions" @click.stop>
			<component v-for="(action, index) in entity.ui.actions" :is="action.component || 'icon-button'" v-bind="action" @click="handleAction(action)" v-b-tooltip.hover="action.text" />
			<!-- <icon-button v-for="(action, index) in entity.ui.actions" :key="index" :icon="action.icon" v-b-tooltip.hover="action.text" :variant="action.variant" @click="handleAction(action)" /> -->
			<icon-button v-if="$scopedSlots && $scopedSlots.dropdown" icon="more-vertical">
				<slot name="dropdown" :entity="entity"></slot>
			</icon-button>
			<icon-button v-if="$listeners && $listeners.destroy" icon="x" @click.native.stop="$emit('destroy', entity)" v-b-tooltip.hover="destroyTooltip" />
			<fake-worker v-if="debug && $admin" />
			<!-- <icon-button v-if="debug && $admin" icon="zap" tooltip="Launch fake worker" @click="$jobs.fake(entity)" /> -->
		</div>

		<!-- BADGES -->
		<div class="entity-badges" v-if="entity.ui.badges || badges || showType">
			<b-badge v-if="showType && !loading" :variant="entity.variant" pill>{{entity._entity_type ? entity._entity_type.toUpperCase() : ''}}</b-badge>
			<b-badge v-if="!loading" v-for="badge in (badges || entity.ui.badges)" :key="badge.text" :variant="badge.variant || 'info'" class="card-badge" pill>{{badge.text}} <icon v-if="badge.icon" :value="badge.icon" /></b-badge>
			<b-skeleton width="20%" v-if="loading" />
		</div>


		<!-- BODY -->
		<div class="entity-body">
			<div class="entity-avatar" v-if="!noAvatar">
				<avatar v-if="!loading" class="mr-3" :size="size" v-bind="entity.ui.avatar" ref="avatar" />
				<b-skeleton type="avatar" v-if="loading" class="mr-3" />
			</div>
			<div class="entity-content">
				<span v-if="!loading" class="d-block entity-title" v-html="title || entity.ui.title"></span>
				<b-skeleton v-if="loading" width="60%" class="d-block entity-title" />
				<span v-if="!loading" class="d-block entity-subtitle" v-html="entity.ui.subtitle"></span>
				<b-skeleton v-if="loading" width="75%" class="d-block entity-subtitle" />
				<span class="entity-description mt-2 text-muted" v-html="entity.ui.description" v-if="entity.ui.description"></span>
				<b-skeleton v-if="loading" width="75%" class="d-block entity-description" />
				<div v-if="entity.ui.details" class="entity-details">
					<span v-for="detail in entity.ui.details" class="detail">
						<b-skeleton v-if="detail.loading" height="100%" width="1em" class="mr-1" />
						<b-skeleton v-if="detail.loading" height=100% width="70%" />
						<icon v-if="detail.icon && !detail.loading" v-model="detail.icon" :framework="detail.framework" :variant="detail.icon_variant || 'info'" />
						<span v-if="detail.text && !detail.loading" :style="{color: $staycolors[detail.text_variant || 'dark']}" class="detail-text" v-html="detail.text"></span>
					</span>
				</div>
				<div v-if="entity.ui.footer" class="entity-footer">{{entity.ui.footer}}</div>
				<div v-if="entity.author_name && !noCreatedAt" class="entity-author">{{entity.author_name}}</div>
				<div v-if="entity.created_at && !noCreatedAt" class="entity-created-at text-muted">{{entity.created_at | ago('Created')}}</div>
			</div>
		</div>
	</button>
</template>

<script>
	import Avatar from 'common/avatar'
	import Icon from 'common/icon'
	import Entity from 'entity'
	import Blink from 'mixins/blink'
	import {ago} from 'functions/date'
	import Dot from 'common/dot'
	import IconButton from 'common/icon_button'
	import Jobs from 'mixins/jobs'
	import { BSkeleton, BBadge } from 'bootstrap-vue'

	// Components used in entity-actions:
	import ClipboardButton from 'common/clipboard_button'


	export default {
		name: 'entity-card',
		props: {
			value: Object,
			showType: Boolean,
			noAvatar: Boolean,
			noViewedIndicator: Boolean,
			noCreatedAt: Boolean,
			selected: Boolean,
			size: {
				type: String,
				default: 'md'
			},
			flush: Boolean,
			destroyTooltip: String,
			card: Boolean,
			subscribe: Boolean,
			disabled: Boolean,
			redirect: Boolean,

			// Overrides for the content:
			title: String,
			badges: Array,
		},
		data(){
			return {
				loading: false,
				destroyed: false,
			}
		},
		methods: {
			handleClick(evt){
				if(this.redirect && this.entity.url){
					window.location.href = this.entity.url
				}
				this.$emit('click')
			},
			handleAction(action_obj){
				let {emit, action} = action_obj
				if(emit) this.$emit('emit')
				if(action) action(this.entity)
			},
			handleUpdate(entity){
				this.$emit('input', {...this.entity, ...entity})
				if(entity.avatar) this.$nextTick(this.$refs.avatar.refresh)
			},
			handleDestroy(val){
				this.destroyed = true
				this.remove_subscribers()
				this.$emit('destroyed', val)
			},
			set_subscribers(){
				this.$root.$on(`${this.entity.plural}:${this.entity.id}:updated`, this.handleUpdate)
				this.$root.$on(`${this.entity.plural}:${this.entity.id}:destroyed`, this.handleDestroy)
			},
			remove_subscribers(){
				this.$root.$off(`${this.entity.plural}:${this.entity.id}:updated`, this.handleUpdate)
				this.$root.$off(`${this.entity.plural}:${this.entity.id}:destroyed`, this.handleDestroy)
			},

		},
		mounted(){
			if(this.subscribe) this.set_subscribers()
			this.$jobs.initialize(this.entity)
		},
		filters: {ago: ago},
		components: {
			Avatar, Icon, IconButton, Dot, BBadge, BSkeleton,
			ClipboardButton
		},
		mixins: [Entity, Blink, Jobs]
	}

</script>

<style lang="scss">
	@import 'stylesheets/colors';
	@import 'stylesheets/sizes';
	@import 'stylesheets/entity';
	@import 'stylesheets/blink';

	.entity-card {
		position: relative;
		//cursor: pointer;
		background-color: Transparent;
		border: none;
		padding: 2rem;
		display: flex;
		width: 100%;
		flex-direction: column;
		text-align: inherit;
		@include font-sizes;
		@include entity;

		&.flush {
			padding: 0rem !important;
		}

		&.list-group {
			background-color: white;
		}


		.viewed-indicator {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			display: flex;
			align-items: center;
			margin-left: 0.5em;
		}

		.entity-badges {
			display: block;
			width: 100%;
			text-align: right;
			font-size: 0.85em;
			margin-bottom: 0.85em;

			.badge {
				text-transform: uppercase;
			}
		}

		.entity-actions {
			position: absolute;
			top: -1em;
			right: 0;
		}

		.entity-body {
			display: flex;
			width: 100%;

			.entity-avatar {
				display: flex;
				align-items: center;
			}

			.avatar {
				flex-grow: 1;
			}
		}

		.entity-content {
			flex-grow: 2;
			overflow: hidden;
			//margin-top: 0.75em;

			.entity-title {
				font-size: 1em;
				font-weight: 500;
				line-height: 1.25em;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}

			.entity-subtitle {
				font-weight: 300;
				font-size: 0.75em;
				margin-bottom: 1.5em;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.entity-description {
				display: -webkit-box;
				font-weight: 100;
				font-size: 0.75em;
				min-height: 0.75em;
				text-overflow: ellipsis;
				overflow: hidden;
				-webkit-line-clamp: 4;
				line-clamp: 4;
				-webkit-box-orient: vertical;
			}

			.entity-details {
				margin-top: 1em;

				.detail {
					display: flex;
					height: 0.8em;
					align-items: center;
					margin-bottom: 0.75em;
					font-size: 0.8em;


					.icon {
						width: 1.25em;
						height: 1.25em;
						line-height: 1.25em;
						margin-right: 0.25em;
						font-size: 1.25em;
					}

					.detail-text {
						//font-size: 0.8em;
						//line-height: 0.8em;
						display: block;
						font-weight: 200;
					}
				}
			}
		}

		

		.entity-footer, .entity-author, .entity-created-at {
			display: box;
			width: 100%;
			font-size: 0.5em;
			font-weight: 700;
			text-align: right;
		}
		.entity-author{
			font-weight: 400;
		}

		.entity-created-at {
			font-weight: 500;
		}

	}

</style>