// Root mixin common across all application types:
// Home, Admin, Organizations, Clients, Public

// Anything defined here will be available in EVERY
// pack file. This file is inherited by the controller-
// specific mixins.

import Vue from 'vue/dist/vue.esm'
import Alerts from 'common/alerts'
import Modals, {RootModalsMixin} from 'plugins/stay-modals'
import {StayCommon} from 'plugins/stay-common'
import 'stylesheets/global'
import 'images/favicon'

Vue.use(Modals)
Vue.use(StayCommon)

export default {

	components: { Alerts },
	mixins: [RootModalsMixin],

	data(){
		return {
			csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
			pageData: pageData,
			window: {
				height: 0,
				width: 0
			},
			alerts: alerts,
			alert: {
				danger: msg => this.alerts.push({type: 'danger', message: msg})
			}
		}
	},

	methods: {
		handleResize() {
			this.window.width = window.innerWidth;
			this.window.height = window.innerHeight;
		}
	},

	created() {
		window.addEventListener('resize', this.handleResize)
		this.handleResize()
	},

	mounted(){
		console.log("      _")
		console.log("     | |")
		console.log("  ___| |_ __ _ _   _ ")
		console.log(" / __| __/ _` | | | |")
		console.log(" \\__ \\ || (_| | |_| |")
		console.log(" |___/\\__\\__,_|\\__, |")
		console.log("                __/ |")
		console.log("               |___/ ")
	}

}