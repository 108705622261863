<template>
	<div :class="`minimized-modal`">
		<button :class="`${variant}`" @click="handleClick">
			<dot variant="light" pulse />
			<icon :value="icon" v-if="icon" />
			{{text}}
		</button>
	</div>
</template>

<script>
	import Icon from 'common/icon'
	import Dot from 'common/dot'

	export default {
		name: 'minimized-modal',
		components: {Dot, Icon},
		props: {
			modalProps: Object,
			icon: String,
			text: String,
			variant: {
				type: String,
				default: 'info'
			}
		},
		methods: {
			handleClick(){
				this.$emit('click')
			}
		}
	}
</script>

<style lang="scss">
	@import "stylesheets/colors";
	@import "stylesheets/variables";

	.minimized-modal {

		button {
			border: none;
			@include background-colors;
			@include variant-text-colors;


			max-width: 90%;
			min-width: 15em;
			text-align: left;
			padding: 1rem;
			min-height: 3rem;
			border-radius: 4px 4px 0 0;
			box-shadow: $box-shadow;
		}
		
	}
</style>
