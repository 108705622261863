<template>
	<div class="dog-wrapper">
		<transition name="fade">
      		<img class="dog" :src="dog" @load="onLoaded" v-show="loaded">
    	</transition>
    </div>
</template>

<script>
	import DogBgImage from 'images/dog_bg'

	export default {
		name: 'dog-bg',
		data(){
			return {
				dog: DogBgImage,
				loaded: false
			}
		},
		methods: {
			onLoaded(){
				this.loaded = true
			}
		}
	}
</script>

<style lang="scss">
	@import 'stylesheets/colors';
	.dog-wrapper {
		position: fixed;
		bottom: 0;
		background: $light-color;
		z-index: -1;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.dog-wrapper img {
		position: absolute;
		left: 50%;
		bottom: 0;
		width: 1000px;
	}

	@media only screen and (max-width: 600px) {
		.dog-wrapper img {
			position: absolute;
			left: 10%;
			bottom: 0;
			width: 600px;
		}
	}


</style>