<template>
	<div class="home-footer w-100 d-flex justify-content-between p-3">
		<small>Copyright 2019 Refined K-9 | <a href="/privacy">Privacy Policy</a></small>
		<small class="text-right">Made with &#x2764; in San Francisco</small>
	</div>
</template>

<script>
	export default {
		name: 'home-footer'
	}
</script>

<style lang="scss">

	.home-footer {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}

</style>

