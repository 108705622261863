<template>
	<div class="code-entry">
		<span class="entry-header">Please enter the 6-digit code that was sent to your phone</span>
		<div class="entry-container">
			<input type="number" v-model="code" maxlength="4" ref="hiddenInput" @blur="$refs.hiddenInput.focus()" :name="name" :id="name" v-focus />
			<div class="input-cover d-flex justify-content-center" @click="$refs.hiddenInput.focus()">
				<div v-for="n in 6" class="entry">{{position(n)}}</div>
			</div>
		</div>
		<transition name="fade">
			<div class="code-retry" v-show="show_retry">Didn't receive the code? <b-button variant="link" @click="$emit('back')">Try sending again.</b-button></div>
		</transition>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				code: null,
				show_retry: false,
				timeout: null
			}
		},
		props: {
			device: {
				type: String,
				required: true
			},
			name: {
				type: String,
				default: 'code'
			}
		},
		computed: {
			icon(){
				return (this.device == "email" ? "mail" : "smartphone")
			}
		},
		methods: {
			position(n){
				if((this.code != null) && (this.code.length >= n)){
					return this.code[n-1]
				} else {
					return ''
				}
			}
		},
		watch: {
			code() {
				if(this.code.length == 6){
					this.$emit('input', this.code)
				} else {
					clearTimeout(this.timeout)
					this.timeout = setTimeout(()=>{this.show_retry = true}, 10000)
				}
			}
		},
		mounted() {
			this.$nextTick(() => this.$refs.hiddenInput.focus())
			this.timeout = setTimeout(()=>{this.show_retry = true}, 10000)
		}
	}
</script>

<style lang="scss">
	@import 'stylesheets/colors.scss';

	.code-entry {
		font-size: 48px;
		.entry-header {
			font-size: 0.8rem;
			text-align: center;
			display: block;
			width: 100%;
		}

		.entry-icon {
			width: 100%;
			display: block;
			text-align: center;

			svg {
				width: 1em;
				height: 1em;
			}
		}

		.entry-container {
			width: 100%;
			height: 2em;
			position: relative;

			input {
				position: relative;
				width: 100%;
				height: 100%;
				color: white;
			}

			.input-cover {
				display: block;
				position: absolute;
				height: 100%;
				width: 100%;
				background-color: white;
				left: 0;
				top: 0;
				cursor: pointer;
				z-index: 2;
			}

			.entry {
				width: 1em;
				height: 1.5em;
				border-bottom: 5px solid $secondary-color;
				margin: 0.1em;
				text-align: center;
			}
		}

		.code-retry {
			width: 100%;
			text-align: center;
			font-size: 0.8rem;
		}
	}

</style>