<template>
	<div class="authenticators w-full">
		<b-list-group v-if="!pending" class="mb-3">
			<b-list-group-item v-for="authenticator in authenticators" :key="authenticator.uid" class="d-flex flex-row align-items-center" button :disabled="loading" @click="authenticate(authenticator)" :id="authenticator.uid">
				<icon :value="authenticator.icon" variant="info" class="mr-3" />
				<div class="d-flex flex-column">
					<div class="w-full">{{authenticator.name}}</div>
					<small>{{authenticator.description}}</small>
					<xsmall v-if="debug">{{authenticator.uid}}</xsmall>
					<xsmall v-if="debug">{{authenticator.created_at}}</xsmall>
				</div>
			</b-list-group-item>
		</b-list-group>

		<div v-if="authenticators.length == 0 && !options.allow_authenticator_creation" class="mt-3">
			<form action="/user/activate" method="POST">
				<p class="mt-3 mb-3">You need to add a form of identity verification to your account. To do this, we will need to reactivate your account. Click below and we will send you a new welcome email.</p>
				<input type='hidden' name="authenticity_token" :value="$root.csrf" />
				<b-button type="submit" block>Send me instructions</b-button>
			</form>
		</div>

		<b-dropdown v-if="options.allow_authenticator_creation && !pending" text="Add a new verification method" block menu-class="p-0">

			<!-- AUTHENTICATORS: SMS -->
			<b-list-group-item class="d-flex flex-row align-items-center" button @click="handleNewSms" :disabled="loading">
				<icon value="smartphone" variant="info" class="mr-3" />
				<div class="d-flex flex-column">
					<div class="w-full">Text me an access code</div>
					<small>Choose a phone number where we can send you an access code</small>
				</div>
			</b-list-group-item>

			<!-- AUTHENTICATORS: WEBAUTHN -->
			<b-list-group-item class="d-flex flex-row align-items-center" v-if="supports_webauthn" button :disabled="loading" @click="handleNewWebauthn">
				<icon value="key" variant="info" class="mr-3" />
				<div class="d-flex flex-column">
					<div class="w-full">Verify using this device</div>
					<small>You can use authenticators built into your browser</small>
				</div>
			</b-list-group-item>

		</b-dropdown>

		<!-- SMS AUTHENTICATION VERIFICATION -->
		<b-list-group v-if="device_type == 'UserPhone' && pending">
			<form method="POST" :action="`/user/authenticate/verify`" ref="code_form">
				<b-list-group-item>
					<input type="hidden" name="authentication[uid]" :value="authentication.uid" />
					<code-entry device="UserPhone" @input="handleCodeEntry" name="authentication[code]" />
					<input type="hidden" name="authenticity_token" :value="$root.csrf" />
				</b-list-group-item>
			</form>
		</b-list-group>


		<!-- WEBAUTHN VERIFICATION -->
		<b-list-group v-if="pending && device_type == 'WebauthnCredential'">
			<form method="post" action="/user/authenticate/verify" ref="webauthn_form">
				<b-list-group-item>
					Authentication has been requested from your browser.
					<input type="hidden" name="authentication[uid]" :value="authentication.uid" />
					<input type="hidden" name="authentication[credentials]" :value="credentials" />
					<input type="hidden" name="authenticity_token" :value="$root.csrf" />
					<b-button class="mt-3" block @click="authenticate(authenticator)">Try again</b-button>
				</b-list-group-item>
			</form>
		</b-list-group>

		<b-button variant="text" v-if="pending" size="sm" class="mt-3" @click="handleCancel">< Use a different authentication method</b-button>
		<b-button variant="text" v-if="options.allow_cancel" size="sm" class="mt-3" @click="handleBack">Cancel and return ></b-button>



	</div>
</template>

<script>
	import { BListGroup, BListGroupItem, BDropdown } from 'bootstrap-vue'
	import Icon from 'common/icon'
	import CodeEntry from './code_entry'
	import PhoneNumberModal from 'common/phone_number_modal'
	import Http from 'mixins/http'
	import * as WebAuthnJSON from "@github/webauthn-json"
	import Browser from 'functions/browser'

	export default {
		name: 'authenticators',
		components: { BListGroup, BListGroupItem, BDropdown, Icon, CodeEntry },
		mixins: [Http],
		modals: [PhoneNumberModal],
		props: {
			authenticators: {
				type: Array,
				default: ()=>[]
			},
			authentication: Object, // The current authentication object pending for login
			user_phones: {
				type: Array,
				default: () => []
			}
		},
		data(){
			return {
				loading: false,
				credentials: null
			}
		},

		computed: {
			supports_webauthn(){
				return typeof(window.PublicKeyCredential) != "undefined"
			},

			options(){
				return this.authentication && this.authentication.authentication_data_options || {}
			},

			authenticator(){
				return this.authentication && this.authentication.authenticator
			},

			device_type(){
				return this.authenticator && this.authenticator.device_type
			},

			pending(){
				return this.authentication.state == 'pending'
			}
		},

		methods: {

			handleNewSms(){
				this.$modals().show('phone-number-modal', {user_phones: this.user_phones}).then(modal => {
					modal.$on('new', this.handleNewPhone)
					modal.$on('selected', this.handleSelectedPhone)
				})
			},

			async handleNewWebauthn(){
				try {
					let auth = await this.update_authentication({
						authenticator: {
							device_type: 'WebauthnCredential',
							device: {
								name: `${Browser.name} on a${['a','e','i','o','u'].includes(this.os[0].toLowerCase()) ? 'n' : ''} ${this.os} device`
							}
						}
					})
					this.credentials = JSON.stringify(await WebAuthnJSON.create({"publicKey": auth.options}))
					this.$nextTick(() => this.$refs.webauthn_form.submit())
					return true
				} catch(e){
					throw(e)
				}
			},

			async handleWebauthnChallenge(){
				console.log("Webauthn Challenge!")
				try{
					this.credentials = JSON.stringify(await WebAuthnJSON.get({"publicKey": this.authentication.options}))
					this.$nextTick(() => this.$refs.webauthn_form.submit())
				} catch(e) {
					throw(e)
				}
			},

			handleNewPhone(phone){
				this.put(`/user/authenticate`, {
					authentication: {
						authenticator: {
							device_type: 'UserPhone',
							device: phone
						}
					}
				}).then(this.handleChallengeResponse)
			},

			handleSelectedPhone(phone){
				this.$modals().hide('phone-number-modal')
				this.put(`/user/authenticate`, {
					authentication: {
						authenticator: {
							device_type: 'UserPhone',
							device_id: phone.id
						}
					}
				}).then(this.handleChallengeResponse)
			},

			handleChallengeResponse(res){
				console.log(res)
				this.$emit('update:authentication', res.data.authentication)
				if(!this.authenticators.some(a => a.uid == res.data.authentication.authenticator.uid)){
					this.$emit('update:authenticators', [...this.authenticators, ...[res.data.authentication.authenticator]])
				}
				console.log(res.data.authentication.authenticator.device_type == 'WebauthnCredential')
				if(res.data.authentication.authenticator.device_type == 'WebauthnCredential') this.$nextTick(this.handleWebauthnChallenge)
			},

			handleBack(){
				window.location.href = this.options.redirect
			},

			handleCancel(){
				this.update_authentication({
					authenticator_id: null,
					state: 'created'
				})
			},

			handleCodeEntry(code){
				this.loading = true
				this.$refs.code_form.submit()
			},

			handleCodeResponse(res){
				console.log(res)
			},

			async update_authentication(val){
				let res
				try {
					res = await this.put("/user/authenticate", {authentication: val})
					this.$emit('update:authentication', res.data.authentication)
					return res.data.authentication
				} catch(e){
					throw(e)
				}
			},

			authenticate(authenticator){
				this.put(`/user/authenticate`, {
					authentication: {
						authenticator_id: authenticator.id
					}
				}).then(this.handleChallengeResponse)
			}


		}

	}
</script>
