<template>
	<div :class="{
		avatar: true,
	 	[size]: true,
	 	[variant]: true,
	 	noBackground: noBackground,
	 	'no-circle': noCircle,
	 	clickable: $listeners.click ? true : false
	 }" @click="$emit('click')" :style="_hex ? `background-color: ${_hex} !important;` : ''" v-loading.center="noCircle && _loading">
		<!-- <div class="avatar-loader" v-if="_loading">
			<b-skeleton height="100%" width="100%" />
		</div> -->
		<div :class="`avatar-content ${variant}`">
			<icon v-model="icon" v-if="icon" />
			<div v-if="text" :class="`avatar-text`">{{text}}</div>
		</div>
		<div class="overlay" v-if="brightness != undefined" :style="`background-color: ${this.overlayBackground}`"></div>
		<transition name="fade">
			<div class="avatar-loader" v-if="!noCircle && _loading"></div>
		</transition>
	</div>
</template>

<script>

	import Icon from 'common/icon'
	import { BSkeleton } from 'bootstrap-vue'

	// This component is expected to receive string or objects that contain either:
	// 1. a string with initials (DL or O)
	// 2. a string with an image URL
	// 3. a string which is an icon ('mail')

	export default {
		props: {
			size: {
				type: String,
				default: "md"
			},
			noBackground: Boolean,
			noCircle: Boolean,
			loading: Boolean,
			icon: String,
			image: String,
			text: String,
			variant: {
				type: String,
				default: 'dark',
			},
			hex: String,
			// text_variant: String,
			brightness: Number
		},
		data(){
			return {
				internal_image: null,
				image_loaded: false,
				internal_hex: null // Used when updating the avatar
			}
		},
		computed: {
			_loading(){
				return this.loading || !this.image_loaded
			},
			_hex(){
				return this.internal_hex || this.hex
			},
			overlayBackground(){
				if(this.brightness > 0){
					return `rgba(255,255,255,${this.brightness/100})`
				} else if(this.brightness < 0){
					return `rgba(0,0,0,${-this.brightness/100})`
				} else {
					return "Transparent"
				}
			},
		},
		methods: {
			fetchImage(force=false){
				this.internal_image = new Image()
				this.internal_image.onload = this.onImageLoad
				this.internal_image.src = `${this.image}${force ? `#${new Date().getTime()}` : ''}`
			},
			onImageLoad(){
				this.$el.style.backgroundImage = `url(${this.internal_image.src})`
				this.image_loaded = true
			},
			// This is used by entity/avatar to update the image when it is
			// changed by the user in the UI
			updateImage(image_to_entity){
				if(image_to_entity.avatar){
					this.image_loaded = false
					this.internal_image = new Image()
					this.internal_image.onload = this.onImageLoad
					this.internal_image.src = image_to_entity.avatar
				} else {
					this.image_loaded = true
					this.internal_image = null
					this.$el.style.backgroundImage = null
				}
				this.internal_hex = image_to_entity.hex
			},
			refresh(){
				if(this.image) this.fetchImage(true) 
			}
		},
		mounted(){
			if(this.image && this.image!='avatar'){
				this.fetchImage()
			} else {
				this.image_loaded = true
			}
		},
		components: {Icon, BSkeleton}
	}

</script>

<style lang="scss">
	@import 'stylesheets/colors';
	@import 'stylesheets/variables';
	@import 'stylesheets/sizes';
	.avatar {
		@include background-colors;
		@include variant-text-colors;
		@include variant-text-svg-fill-colors;
		@include width-sizes(3);
		@include height-sizes(3);
		@include font-sizes;

		border-radius: 500px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		background-size: cover;
		overflow: hidden;

		&.no-background {
			background-color: Transparent;
		}

		&.clickable {
			cursor: pointer;
		}


		&.no-circle {
			border-radius: 0 !important;

			img {
				clip-path: none !important;
			}
		}

		.avatar-loader{
			display: flex;
			position: absolute;
			pointer-events: none;
			align-items: center;
			justify-content: flex-end;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 2;

			&::after {
				content: "";
				border: 2px solid white;
				border-radius: 50%;
				border-top: 2px solid $info-color;
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,0.3);
				-webkit-animation: spin 2s linear infinite; /* Safari */
				animation: spin 1s linear infinite;
			}
		}

		@-webkit-keyframes spin {
			0% { -webkit-transform: rotate(0deg); }
			100% { -webkit-transform: rotate(360deg); }
		}

		@keyframes spin {
			0% { transform: rotate(0deg); }
			100% { transform: rotate(360deg); }
		}

		.avatar-image {
			width: 100%;
			height: 100%;
			z-index: -1;
		}

		.avatar-content {
			@include variant-text-colors;
			position: absolute;
			top: 0;
			left: 0;
			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			z-index: 2;
		}

		.overlay {
			@include fill;
			z-index: 1;
		}
	}

</style>
