// Common Date Functions

import { formatDistanceToNow, add, set, differenceInDays, intervalToDuration, roundToNearestMinutes, isSameDay } from 'date-fns'
import Pluralize from 'pluralize'

////
// Date distances

export const ago = function(date, preface=null){
	if(!date) return ''
	return `${preface ? preface + ' ' : ''}${typeof(date) == 'string' ? formatDistanceToNow(new Date(date)) : formatDistanceToNow(date)} ago`
}

export const ago_or_date_after = function(date, days=5, preface=null){
	if(!date) return ''
	date = typeof(date) == 'string' ? new Date(date) : date
	return days_ago(date) > days ? short_date(date) : ago(date, preface)
}

export const days_ago = function(date, start=new Date){
	date = typeof(date) == 'string' ? new Date(date) : date
	start = typeof(start) == 'string' ? new Date(start) : start
	return differenceInDays(start, date)
}

export const in_future = function(date){
	date = typeof(date) == 'string' ? new Date(date) : date
	return date > new Date
}

export const in_past = function(date){
	return !in_future(date)
}

export const date_difference = function(start, end){
	return intervalToDuration({start: start, end: end})
}

export const age_string = function(birthdate){
	if(!birthdate) return 'Unknown Age'
	birthdate = typeof(birthdate) == 'string' ? new Date(birthdate) : birthdate
	let diff = date_difference(birthdate, new Date)
	return `${Pluralize('year', diff.years, true)}, ${Pluralize('month', diff.months, true)}`
}

export const date_add = function(date, add_object){
	date = typeof(date) == 'string' ? new Date(date) : date
	return add(date, add_object) // Object eg: {years: 2, minutes: 3}, etc
}

export const date_set = function(date, set_object){
	date = typeof(date) == 'string' ? new Date(date) : date
	return set(date, set_object) // Object eg: {years: 2, minutes: 3}, etc
}

export const is_same_day = isSameDay




export const long_date = function(date, text_if_null=''){
	if(!date) return text_if_null
	date = typeof(date) == 'string' ? new Date(date) : date
	return Intl.DateTimeFormat(navigator.language, { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric' }).format(date)
}

export const short_date = function(date, text_if_null=''){
	if(!date) return text_if_null
	return Intl.DateTimeFormat(navigator.language, { month: 'short', day: 'numeric', year: 'numeric' }).format(new Date(date))
}

export const date_abbreviation = function(date){
	if(!date) return ''
	date = typeof(date) == 'string' ? new Date(date) : date
	return Intl.DateTimeFormat(navigator.language, {month: 'numeric', day: 'numeric'}).format(date)
}

export const event_time = function(start, end, all_day=false){
	start = start ? new Date(start) : undefined
	end = end ? new Date(end) : undefined
	if(start && end && start.getDate() == end.getDate()){
		return !all_day ?
			`${Intl.DateTimeFormat(navigator.language, { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }).format(start)} - ${Intl.DateTimeFormat(navigator.language, { hour: 'numeric', minute: 'numeric' }).format(end)}` :
			Intl.DateTimeFormat(navigator.language, { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric' }).format(start)
	} else if(start && end) {
		return !all_day ? 
			`${Intl.DateTimeFormat(navigator.language, { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }).format(start)} - ${Intl.DateTimeFormat(navigator.language, { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }).format(end)}` :
			`${Intl.DateTimeFormat(navigator.language, { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric' }).format(start)} - ${Intl.DateTimeFormat(navigator.language, { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric' }).format(end)}`
	} else {
		return ''
	}
}

export const invite_time = function(invite){
	// An invite can have start_time and end_time for a time-bound event
	// or start_date and end_date for an all-day event
	let all_day = invite.start_date && invite.end_date ? true : false
	return event_time(invite.start_date || invite.start_time, invite.end_date || invite.end_time, all_day)
}


export const time = function(date){
	if(!date) return ''
	date = typeof(date) == 'string' ? new Date(date) : date
	return new Intl.DateTimeFormat(navigator.language, {timeStyle: 'short'}).format(date)
}

export const round_to_15 = function(date){
	if(!date) return null
	return roundToNearestMinutes(date, {nearestTo: 15, roundingMethod: 'ceil'})
}