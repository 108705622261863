var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(_vm.tooltip),expression:"tooltip",modifiers:{"hover":true}},{name:"loading",rawName:"v-loading.center",value:(!_vm.circle && _vm.loading),expression:"!circle && loading",modifiers:{"center":true}}],staticClass:"icon-button"},[(_vm.count)?_c('div',{staticClass:"count"},[_vm._v("\n\t\t"+_vm._s(_vm.count)+"\n\t")]):_vm._e(),_vm._v(" "),(!_vm.$slots.default)?_c('button',{class:{
			'hover': _vm.hover,
			'flush': _vm.flush,
			'pill': _vm.pill,
			'circle': _vm.circle,
			'disabled': _vm.disabled,
			'card': _vm.card,
			'selected': _vm.selected,
			'border': _vm.border,
			[_vm.variant]: true,
			[_vm.size]: true
		},attrs:{"disabled":_vm.disabled},on:{"click":_vm.handleClick}},[_c('icon',{attrs:{"value":_vm.icon,"framework":_vm.framework,"disabled":_vm.disabled}}),_vm._v(_vm._s(_vm.title)+"\n\t")],1):_vm._e(),_vm._v(" "),(_vm.$slots.default && !_vm.card)?_c('b-dropdown',{ref:"dropdown",attrs:{"no-caret":!_vm.showCaret,"right":"","dropup":_vm.dropup,"dropright":_vm.dropright,"text":_vm.title,"tabindex":_vm.tabindex.toString(),"disabled":_vm.disabled,"menu-class":{
			...{
				'icon-button-menu': true
			},
			..._vm.menuClass
		},"toggle-class":{
			//'bg-transparent': true,
			'hover': _vm.hover,
			'flush': _vm.flush,
			'pill': _vm.pill,
			'circle': _vm.circle,
			'disabled': _vm.disabled,
			'card': _vm.card,
			'selected': _vm.selected,
			'border': _vm.border,
			[_vm.variant]: true,
			[_vm.size]: true
		}},on:{"show":function($event){return _vm.$emit('show')},"hide":function($event){return _vm.$emit('hide')}},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('icon',{attrs:{"value":_vm.icon,"framework":_vm.framework,"disabled":_vm.disabled}}),_vm._v(_vm._s(_vm.title)+"\n\t\t")]},proxy:true},{key:"default",fn:function({hide}){return [_vm._t("default",null,{"hide":hide})]}}],null,true)}):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.circle && (_vm.loading || _vm.internal_loading))?_c('div',{staticClass:"icon-button-circle-loader"}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }