<template>
  <b-list-group>
  	<b-list-group-item>
			<form action="/user/login" method="post">
				<label>Email</label>
				<b-form-input
					id="user[email]"
					name="user[email]"
					v-model="user.email"
					type="email"
					required
				/>
				<label class="mt-3">Password</label>
				<b-form-input
					id="user[password]"
					name="user[password]"
					v-model="user.password"
					type="password"
					required
				/>
				<b-form-checkbox
				id="user[remember_me]"
				name="user[remember_me]"
				v-model="user.remember_me"
				value="true"
				unchecked-value="false"
				class="mt-3"
				>
				Keep me logged in
				</b-form-checkbox>
				<b-button type="submit" variant="dark" class="mt-3 mb-3" block>Login</b-button>
				<input type="hidden" id="authenticity_token" name="authenticity_token" :value="authenticity_token">
				<small class="mt-3"><a href="/user/password_reset">Forgot your password?</a></small>
			</form>
		</b-list-group-item>
	</b-list-group>

</template>

<script>
	import {BFormInput, BFormCheckbox, BListGroup, BListGroupItem} from 'bootstrap-vue'
	export default {
	data() {
	  return {
		user: {
		  email: "",
		  password: "",
		  remember_me: true,
		  redirect: "/me"
		},
		authenticity_token: this.$root.csrf
	  }
	},
	components: {'b-form-checkbox': BFormCheckbox, BFormInput, BListGroupItem, BListGroup}
  }
</script>

<style lang="scss">
	@import 'stylesheets/variables';

	.sign-in-container {
		@include fill;

		.sign-in {
			width: 30em;
			max-width: 100%;

		}
	}
</style>
