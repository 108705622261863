// Used with the blink.scss stylesheet to create a simple blink animation
// on a component. Can be called as blink()

// Animation handling
// Call this to handle animation with reflow capabilities:
// https://css-tricks.com/restart-css-animation/

export default {

	methods: {
		blink(){
			this.$el.classList.remove('blink')
			void this.$el.offsetWidth
			this.$el.classList.add('blink')
		},

		delayed_blink(){
			setTimeout(this.blink, 500)
		}
	}

}