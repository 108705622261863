// Common functions and components used across the app
//import ModalsContainer from 'common/modals_container'

// SOME NOTES:

// PROMISES
// Calling $root.$modals().show() or hide() return a promise
// The promise resolves to the component that is mounted on the page so that you can add listeners to it, etc
// e.g. let modal = await this.$root.$modals().show('new-document')
// 		modal.$on('input', value => console.log(value))

// ROOT EMITS
// Modal events will be emitted on $root as well:
// 'modal:{{modal-name}}:shown', component
// 'modal:{{modal-name}}:hidden'
// 'modal:{{modal-name}}:ok', ok-value
// Etc
import findKey from 'lodash/findKey'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import zipObject from 'lodash/zipObject'
import Confirm from 'common/confirm'
import MinimizedModalsContainer from 'common/minimized_modals_container'

export default {
	install (Vue, options) {

		Vue.mixin({

			created(){
				if(this.$options.modals){
					this.$options.modals.forEach(component => {
						if(component.name){
							if(this.$root.modals.some(modal => modal.name == component.name)){
								console.warn("This modal has already been mounted. Skipping...")
								console.warn(component)
							} else {
								Vue.component(component.name, component)
								this.$root.modals.push({
									component: component,
									name: component.name,
									visible: false,
									minimized: false,
									propsData: {}
								})
							}
						} else {
							console.warn("A modal component was provided to the StayModals plugin but no name was found on the component. Ensure you have provided an 'id' for the modal component! This modal will be ignored:")
							console.warn(component)
						}
					})
				}
			}

		})

		const ModalPromise = function(modal_name, _this){

			return new Promise(resolve => {
				_this.$root.$on(`modal:${modal_name}:show`, component => {
					resolve(component)
				})
			})

		}


		const ShowHide = function(){

			return {

				visibility: async function(modal_name, visibility, _this, propsData, propsMap){

					// modal_name: STRING: the name of the modal to be shown or hidden
					// visibility: BOOL: true for show, false for hide
					// _this: COMPONENT: the component initiating the call for the modal
					// propsData: OBJECT: key/value props
					// propsMap: OBJECT: key/value where the key is the prop name and the value is a string containing the name of the variable on _this to watch for changes

					try {
					
						console.log(`${visibility ? 'Show' : 'Hide'} modal: [${modal_name}]`)
						console.log(propsData)

						if(!_this.$root.modals) throw("$modals called but the RootModalsMixin has not been added to the JS pack. Add RootModalsMixin to use this functionality.")

						let index = _this.$root.modals.findIndex(modal => {
							return modal.name == modal_name
						})

						if(index >= 0){

							if(visibility){

								// Components that are dynamically created won't have reactive props. The props
								// or only initialized with a value (provided above) so we need to manually create some watchers
								// on the calling component to update this when the data changes:
								let watcherKeys = {}

								if(!isEmpty(propsMap)){
									watcherKeys = propsMap
									console.log(`Modal [${modal_name}] was mounted with a propsMap. StayModals will update the passed props using the following keys. These keys will be watched for changes to update the modal:`)

								} else {

									console.log(_this._computedWatchers)

									let componentData = {
										..._this.$data || {},
										..._this.$props || {},
										...zipObject(Object.keys(_this._computedWatchers || {}), Object.values(_this._computedWatchers || {}).map(w => w.value)) // Gets all the computed data
									}

									for(const key in propsData){
										let componentKey = findKey(componentData, value => isEqual(value, propsData[key]))
										if(componentKey) watcherKeys[key] = componentKey
									}

									console.log(`Modal [${modal_name}] is being mounted with props but without a propsMap. StayModals attempts to match those passed prop values to the data on the originating component from where the modal was called. Passed props were set to the following keys. These keys will be watched for changes to update the modal:`)


								}

								console.log(watcherKeys)


								for(const key in watcherKeys){
									_this.$watch(watcherKeys[key], newVal => {
										console.log(`Watcher triggered on component [${_this.$options.name}] being watched by modal [${modal_name}] for propsData key [${key}]:`)
										console.log(newVal)
										_this.$set(_this.$root.modals[index].propsData, key, newVal)
									})
								}

							} else {

								// We are hiding this modal so we need to have it $emit the "hide"
								// event. First we need to see if it is currently showing:

								let active_index = _this.$root.$refs.modals_container.$refs.modals.findIndex(component => component.$options.name == modal_name)
								if(active_index >= 0) _this.$root.$refs.modals_container.$refs.modals[active_index].$emit("hide")

							}

							// First we will change the visibility and the propsData on the root modals-container
							// to represent any passed propsData and thhe visibility. This will re-render the
							// modals-container component and cause the modal to mount or un-mount:

							_this.$set(_this.$root.modals[index], 'propsData', propsData)
							_this.$set(_this.$root.modals[index], 'visible', visibility)
							_this.$set(_this.$root.modals[index], 'parent', _this)

							let component = await ModalPromise(modal_name, _this)
							return component
						} else {
							throw(`No modal found with name: [${modal_name}]`)
						}

					} catch(err){
						throw(err)
					}

					
				}

			}
		}


		const Minimize = function(_this, options={}){
			let index = _this.$root.modals.findIndex(modal => modal.name == _this.$options.name)
			if(index >= 0) {
				_this.$set(_this.$root.modals, index, {..._this.$root.modals[index], ...options})
				_this.$root.modals[index].minimized = true
			} else {
				console.warn("No modal found to minimize", _this.$options.name, _this.$root.modals)
			}
		}


		const Maximize = function(modal, _this){
			let index = _this.$root.modals.findIndex(m => m.name == modal.name)
			if(index >=0){
				_this.$root.modals[index].minimized = false
			} else {
				console.warn("No modal found to maximize", modal.name)
			}
		}


		const UpdateModalProps = function(_this, modal, val){
			let index = _this.$root.modals.findIndex(m => m.name == modal.name)
			if(index >=0){
				_this.$root.modals[index].minimized = false
				_this.$set(_this.$root.modals[index].propsData, 'value', val)
			} else {
				console.warn("No modal found to maximize", modal.name)
			}
		}

		const ConfirmModal = function(_this, message, options={}){

			return new Promise((resolve, reject) => {
				let StayConfirm = Vue.extend(Confirm)
				var StayModal = new StayConfirm({
					propsData: {
						message: message,
						...options
					}
				})
				StayModal.$on('confirm', (status) => {
					if(status){
						resolve(true)
					} else {
						resolve(false)
					}
				})
				StayModal.$mount()
			})

		}

		const MessageModal = function(_this, content, options={}){


			// https://bootstrap-vue.org/docs/components/modal#ok-message-box
			return _this.$bvModal.msgBoxOk(content, options)

		}

		// Showing or hiding a modal:
		// The calling component should pass the modal name:
		// this.$modals().show('modal-name')
		//
		// Optionally you can pass propsData:
		// this.$modals().show('modal-name', {value: 1, conditions: 2})
		//
		// Stay-Modals will scan the component for data that looks like the data
		// value of the props that were passed and watch those variabled for changes
		// Optional: Specify the names of the variabled to be watched:
		// this.$modals().show('modal-name', {value: 1, conditions: 2}, {value: 'componentVariableName'})

		Vue.prototype.$modals = function(){
			return {

				show: function(modal_name, propsData={}, propsMap={}){
					return ShowHide().visibility(modal_name, true, this, propsData, propsMap)
				}.bind(this),

				hide: function(modal_name, propsData={}, propsMap={}){
					return ShowHide().visibility(modal_name, false, this, propsData, propsMap)
				}.bind(this),
				minimize(modal_component, minimizedModalOptions={}){
					return Minimize(modal_component, {...{text: 'Minimized Modal', icon: 'minimize-2', variant: 'info'}, ...minimizedModalOptions})
				},
				maximize: function(modal){
					return Maximize(modal, this)
				}.bind(this),

				bound: function(){
					return this
				}.bind(this),

				confirm: function(message, options={}){
					return ConfirmModal(this, message, options)
				}.bind(this),

				message: function(message, options={}){
					return MessageModal(this, message, options)
				}.bind(this),

				updateModalProps: function(modal, val){
					return UpdateModalProps(this, modal, val)
				}.bind(this)

			}
		}

		// Closing the modal (either with the OK, cancel or close buttons should ensure that the visible, property
		// for that modal gets updated back to false. We listen for those events here - these events are thrown off by the
		// Bootstrap Vue modal component that we are currently leveraging)

		// Component.$on("bv::modal::hidden", modal => {
		// 	if(modal.componentId) ShowHide(modal.componentId, false)
		// })

	}
}

const ModalsContainer = {

	name: 'modals-container',

	//components: {MinimizedModal},

	props: {
		value: Array
	},
	data(){
		return {
			mounted_modals_key: 0
		}
	},

	created() {

		this.$root.$on("bv::modal::hidden", bvModal => {
			if(bvModal.componentId) this.$root.$modals().hide(bvModal.componentId)
			if(bvModal.componentId) this.$root.$emit(`modal:${bvModal.componentId}:hide`)
		})

		this.$root.$on("bv::modal::show", bvModal => {
			if(bvModal.componentId) this.$root.$emit(`modal:${bvModal.componentId}:show`, this.$children.find(component => component.$options.name == bvModal.componentId))
		})
	},

	methods: {
		force_render(){
			this.mounted_modals_key = this.mounted_modals_key + 10
		},

		handleEvent(event, modal){
			return function(data){
				this.$emit(`modal:${modal.name}:${event}`, data)
				if(event == 'input') this.$modals().updateModalProps(modal, data)
			}.bind(this)
		},

	},

	computed: {
		visibleModals(){

			return this.value.filter(modal => {
				return modal.visible && !modal.minimized
			})

		},

		minimizedModals(){
			return this.value.filter(modal => {
				return modal.minimized
			})
		}
	},

	// According to this, I need to regenerate the VNodes whenever data from the watchers above is triggered:
	// https://forum.vuejs.org/t/how-do-i-make-props-reactive-when-creating-components-with-createelement/59574/2

	render: function(createElement){

		const ModalsArray = this.visibleModals.map((modal, index) => createElement(modal.component, {
			props: {
				...{visible: true}, 
				...this.visibleModals[index].propsData
			},
			on: {
				ok: this.handleEvent('ok', modal),
				cancel: this.handleEvent('cancel', modal),
				input: this.handleEvent('input', modal),
				hide: this.handleEvent('hide', modal),
				shown: this.handleEvent('shown', modal)
			},
			ref: 'modals',
			refInFor: true,
			key: this.mounted_modals_key + index,
			parent: modal.parent
		}))
		const MinimizedModals = createElement(MinimizedModalsContainer, {
			props: {
				value: this.minimizedModals
			}
		})
		return createElement('div', {attrs: {class: 'modals-container'}}, [...ModalsArray, MinimizedModals])

	}

}

export const RootModalsMixin = {

	components: {
		ModalsContainer
	},

	data(){
		return {
			modals: []
		}
	}

}
