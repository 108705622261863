// NEW entity functions to support common entity UI
// elements and objects across the front end application
//
// The goal of these modules is to unite the rails models with
// front end UI logic and standardize attributes that should
// be used for titles, subtitles, descriptions, etc...
import { ago, event_time, invite_time, date_abbreviation } from 'functions/date'
import startCase from 'lodash/startCase'

const EntityDefaults = {

	_entity_key: entity => `${entity._entity_type}_${entity.id}`,
	singular: entity => entity._entity_type.toLowerCase(),
	plural: entity => `${entity._entity_type.toLowerCase()}s`,
	variant: 'secondary'

}

// These are standard attributes used by event-based models...
// (Invite, Apppointment, OrgEvent, etc)

const EventDefaults = {
	start_time: entity => entity.start_time ? new Date(entity.start_time) : null,
	end_time: entity => entity.end_time ? new Date(entity.end_time) : null,
	time_string: entity => invite_time(entity),
	date_abbreviation: entity => date_abbreviation(entity.start_time || entity.start_date)
}

const Entities = {

	Admin: {
		check: user => (user.role || false) == "admin",
		singular: 'user',
		plural: 'users',
		_strict_entity_type: 'User',
		fullname: user => `${user.fname} ${user.lname}`,
		initials: user => `${user.fname.substr(0,1)}${user.lname.substr(0,1)}`,
		url: user => `/users/${user.id}`,
		variant: 'danger',
		ui: {
			title: 'fullname',
			subtitle: 'title',
			avatar: {
				image: 'avatar',
				text: 'initials'
			}
		}
	},


	Appointment: {
		...{
			check: appointment => Object.keys(appointment).includes('transparent_attendees'),
			singular: 'appointment',
			plural: 'appointments',
			event_time: appointment => invite_time(appointment),
			ui: {
				title: 'name',
				subtitle: 'event_time',
				description: 'description',
				avatar: {
					icon: 'calendar'
				},
				badges: appointment => {
					return [
						...(appointment.start_time ? [] : [{text: 'Needs Scheduling', variant: 'light'}])
					]
				}
			}
		},
		...EventDefaults
	},


	Assignment: {
		check: assignment => Object.keys(assignment).includes('assignment_role_id'),
		singular: 'assignment',
		plural: 'assignments',
		fullname: assignment => `${assignment.user.fname} ${assignment.user.lname}`,
		initials: assignment => `${assignment.user.fname && assignment.user.fname.substr(0,1) || ''}${assignment.user.lname && assignment.user.lname.substr(0,1) || ''}`,
		hex: assignment => assignment.user.hex,
		avatar: assignment => assignment.user.avatar,
		user_entity: assignment => Entity(assignment.user),
		ui: {
			title: 'fullname',
			subtitle: assignment => assignment.user.title,
			avatar: {
				text: 'initials',
				hex: 'hex',
				image: 'avatar'
			},
			badges: assignment => {
				return [{icon: assignment.assignment_role.icon, text: assignment.assignment_role.name, variant: 'light'}]
			}
		}
	},


	Breed: {
		check: breed => Object.keys(breed).includes('life_expectancy'),
		singular: 'breed',
		plural: 'breeds',
		ui: {
			title: 'name',
			subtitle: 'height',
			description: 'description',
			avatar: {
				text: breed => breed.name.slice(0,1).toUpperCase()
			}
		}
	},


	Client: {
		check: user => (user.role || false) == "client",
		singular: 'user',
		plural: 'users',
		_strict_entity_type: 'User',
		fullname: user => `${user.fname} ${user.lname}`,
		initials: user => `${user.fname.substr(0,1)}${user.lname.substr(0,1)}`,
		url: user => `/clients/${user.id}`,
		has_pets: user => user.pets && user.pets.data && user.pets.data.length > 0,
		variant: 'info',
		ui: {
			title: 'fullname',
			subtitle: 'email',
			avatar: {
				image: 'avatar',
				text: 'initials',
				hex: 'hex'
			}
		}
	},


	Document: {
		check: doc => Object.keys(doc).includes("document_type_id"),
		ui: {
			title: "name",
			subtitle: "filename",
			viewed: "viewed",
			avatar: {
				image: "avatar",
				noCircle: true,
				icon: doc => !doc.avatar ? 'file' : null
			},
			badges: doc => {
				return [
					...(doc.status == 'execution_requested' ? [{variant: 'warn', text: 'Signature Requested'}] : []),
					...(doc.status == 'requested' ? [{variant: 'info', text: 'Document Requested'}] : []),
					...(doc.status == 'request_expired' || doc.status == 'execution_expired' ? [{variant: 'secondary', text: 'Expired Request'}] : [])
				]
			},
			details: doc => {
				return [
					//...(doc.jobs.find(d => d.worker == "DocumentClassificationWorker") ? [{loading: true}] : []),
					...(doc.document_type && doc.document_type.name ? [{icon: doc.document_type.icon, text: doc.document_type.name}] : []),
					...(doc.classification_failed ? [{icon: 'question-circle', text: 'Unknown Document', icon_variant: 'danger'}] : []),
					...(doc.signed_at ? [{icon: 'edit-3', text: 'Signed'}] : []),
					...(doc.status == 'needs_execution' ? [{icon: 'signature', icon_variant: 'danger', text: 'Needs Signatures'}] : []),
					...(doc.has_coordinates ? [{icon: 'pen', icon_variant: 'danger', text: 'Fillable Form'}] : [])
				]
			}
		}
	},

	DocumentPage: {
		check: doc => Object.keys(doc).includes("document_id"),
		singular: 'document_page',
		plural: 'document_pages',
		ui: {
			title: page => `Page ${page.page_number + 1}`,
			avatar: {
				image: 'avatar',
				noCircle: true,
				icon: page => !page.avatar ? 'file' : null
			},
			badges: page => {
				return [
					...(page.elements && page.elements.length > 0 ? [{icon: 'file-text', variant: 'dark', text: 'Text Found'}] : []),
					...(page.elements && page.elements.some(el => el.vaccine_ids) ? [{icon: 'prescription', variant: 'dark', text: 'Vaccinations Found'}] : []),
					...(page.elements && page.elements.some(el => el.pet_ids) ? [{icon: 'dog-head', variant: 'dark', text: 'Pets Found'}] : []),
				]
			}
		}
	},


	EmailAddress: {
		check: email_address => Object.keys(email_address).includes('local'),
		singular: 'email_address',
		plural: 'email_addresses',
		ui: {
			title: 'address',
			subtitle: email_address => email_address.default ? 'Default Email' : '',
			avatar: {
				icon: 'mail',
				variant: 'light'
			},
			details: email_address => {
				return [
					...(email_address.opt_outs ? email_address.opt_outs.map(opt_out => { return {icon: 'x', icon_variant: 'danger', text: opt_out} }) : [])
				]
			}
		}
	},


	// NOTE: This should be deprecated soon!
	// Only being used until we migrate Notes
	// to their own model and put created events
	// into Timelineable...

	Event: {
		check: event => Object.keys(event).includes('event_type') && !Object.keys(event).includes('end_time'),
		ui: {
			title: event => startCase(event.event_type),
			description: 'notes',
			avatar: {
				icon: event => event.event_type == 'note' ? 'clipboard' : 'zap',
				variant: 'light',
				text_variant: 'dark'
			}
		}
	},


	Image: {
		check: image => Object.keys(image).includes('image_type'),
		thumbnail: image => {
			switch(image.image_type){
				case 'photo':
					return image.variant_attributes && image.variant_attributes.find(va => va.name == 'thumbnail') && image.variant_attributes.find(va => va.name == 'thumbnail').url
					break
				default:
					return null
			}
		},
		ui: {
			avatar: {
				image: 'thumbnail',
				hex: 'hex',
				noCircle: true
			}
		}
	},


	Invite: {
		...{
			check: invite => Object.keys(invite).includes('partstat') && Object.keys(invite).includes('start_time'),
			start_time: invite => new Date(invite.start_time),
			end_time: invite => new Date(invite.end_time),
			time_string: invite => invite_time(invite),
			partstat_icon: invite => invite.partstat == 'ACCEPTED' ? 'calendar-check' : (invite.partstat == 'DECLINED' ? 'calendar-times' : 'calendar'),
			ui: {
				title: 'name',
				subtitle: 'time_string',
				avatar: {
					icon: 'partstat_icon'
				},
				badges: invite => {
					return [
						...(invite.partstat == 'ACCEPTED' ? [{variant: 'success', text: 'accepted', icon: 'check'}] : []),
						...(invite.partstat == 'DECLINED' ? [{variant: 'danger', text: 'declined', icon: 'x'}] : []),
					]
				}
			}
		},
		...EventDefaults
	},


	Message: {
		check: message => Object.keys(message).includes('subject'),
		ui: {
			title: 'subject',
			description: 'body',
			avatar: {
				icon: 'mail'
			}
		}
	},


	Note: {
		check: note => Object.keys(note).includes('summary'),
		ui: {
			title: 'Internal Note',
			description: 'html',
			avatar: {
				icon: 'clipboard',
				variant: 'light'
			}
		}
	},



	OrgEvent: {
		...{
			check: org_event => Object.keys(org_event).includes("waitlist"),
			singular: 'org_event',
			plural: 'org_events',
			fulladdress: org_event => { return org_event.address && org_event.address.query || null },
			ui: {
				title: 'name',
				subtitle: 'fulladdress',
				avatar: {
					text: 'date_abbreviation',
					image: org_event => org_event.hero && org_event.hero.medium || null,
					variant: org_event => org_event.hero && org_event.hero.background_variant || 'dark',
					text_variant: org_event => org_event.hero && org_event.hero.text_variant || 'light',
					brightness: org_event => org_event.hero && org_event.hero.brightness && parseInt(org_event.hero.brightness) || 0
				},
				badges: org_event => {
					return [
						...(org_event.open_enrollment ? [{text: 'Open Enrollment', variant: 'success'}] : [{text: 'Closed Enrollment', variant: 'warn'}]),
						...(org_event.virtual ? [{text: 'Virtual', variant: 'info'}] : []),
						...(org_event.waitlist ? [{text: 'Waitlist Enabled', variant: 'primary'}] : []),
						...(org_event.canceled ? [{text: 'Canceled', variant: 'danger'}] : [])
					]
				},
				details: org_event => {
					return [
						...[{icon: 'clock', text: org_event.time_string}],
						...[{icon: 'user-friends', text: `${org_event.total_rsvps} RSVP${org_event.total_rsvps != 1 ? 's' : ''} of ${org_event.max_attendees} available`}],
						...(org_event.price && org_event.price > 0 ? [{icon: 'money-bill', text: `$${parseFloat(org_event.price).toFixed(2)}`}] : [{icon: 'money-bill', text: 'FREE'}])
					]
				},
				url: 'url'
			},
		},
		...EventDefaults
	},


	Pet: {
		check: pet => Object.keys(pet).includes("breed_input"),
		url: pet => `/pets/${pet.id}`,
		initials: pet => pet.name.replace(/(<([^>]+)>)/gi, "").substr(0,1).toUpperCase(),
		ui: {
			title: 'name',
			subtitle: pet => pet.breeds && pet.breeds.join(', '),
			avatar: {
				image: 'avatar',
				text: 'initials'
			}
		}
	},


	Prospect: {
		check: prospect => Object.keys(prospect).includes('locale') && !Object.keys(prospect).includes('role'),
		fullname: prospect => `${prospect.fname} ${prospect.lname}`,
		initials: prospect => `${prospect.fname.substr(0,1)}${prospect.lname.substr(0,1)}`,
		url: prospect => `/prospects/${prospect.id}`,
		has_pets: user => user.pets && user.pets.data && user.pets.data.length > 0,
		ui: {
			title: 'fullname',
			subtitle: 'email',
			avatar: {
				text: 'initials'
			}
		}
	},


	TempUserSession: {
		check: session => Object.keys(session).includes("routes"),
		singular: 'temp_user_session',
		plural: 'temp_user_sessions',
		ui: {
			title: 'id',
			details: session => {
				return [
					...(session.session_id ? [{icon: 'user-check', text: 'Session initiated', icon_variant: 'success'}] : [{icon: 'user-x', text: 'Link not clicked'}]),
					...(session.authenticated ? [{icon: 'lock', text: 'Session authenticated', icon_variant: 'success'}] : [{icon: 'unlock', text: 'Session not authenticated'}])
				]
			},
			badges: session => {
				return session.routes.map(route => {return {text: route.friendly_name || `${route.controller}: ${route.action}`, variant: 'secondary', icon: route.authenticated ? 'lock' : null}})
			},
			actions: session => {
				return [
					{component: 'clipboard-button', text: session.url, tooltip: 'Copy link to clipboard'},
					{name: 'click', icon: 'external-link', text: 'Open session', action: ()=> window.open(session.url, '_blank')}
				]
			},
			footer: session => {
				return `Expires ${ago(session.expires_at)}`
			}
		}

	},


	User: {
		check: user => (user.role || false) == "user",
		fullname: user => `${user.fname} ${user.lname}`,
		initials: user => `${user.fname && user.fname.substr(0,1) || ''}${user.lname && user.lname.substr(0,1) || ''}`,
		url: user => `/users/${user.id}`,
		variant: 'warning',
		ui: {
			title: 'fullname',
			subtitle: 'title',
			avatar: {
				text: 'initials',
				image: 'avatar',
				hex: 'hex'
			}
		}
	},



	UserPhone: {
		check: user_phone => Object.keys(user_phone).includes('friendly_name'),
		singular: 'user_phone',
		plural: 'user_phones',
		ui: {
			avatar: {
				icon: user_phone => user_phone.label
			},
			title: 'friendly_name',
			subtitle: 'label',
			badges: user_phone => [...(user_phone.default ? [{text: 'default'}] : [])],
			details: user_phone => [...(user_phone.verified ? [{text: 'Verified', icon: 'check', icon_variant: 'success'}] : [{text: 'Not Verified', icon: 'x', icon_variant: 'info'}])]
		}
	},


	Vaccination: {
		check: vaccination => Object.keys(vaccination).includes("verified_by"),
		name: vaccination => vaccination.vaccine.name,
		ui: {
			avatar: {
				icon: 'syringe'
			},
			title: 'name'
		}
	},


	Vaccine: {
		check: vaccine => Object.keys(vaccine).includes('included_vaccine_ids'),
		ui: {
			title: 'name',
			subtitle: vaccine => vaccine.included_vaccine_ids.length > 0 ? `${vaccine.included_vaccine_ids.length}-in-1 Vaccine` : '',
			description: 'description',
			avatar: {
				icon: 'syringe'
			},
			badges: vaccine => {
				return [
					...(vaccine.category == 'core' ? [{text: 'Core', variant: 'primary'}] : []),
					...(vaccine.category == 'noncore' ? [{text: 'Non-Core', variant: 'secondary'}] : []),
					...(vaccine.category == 'other' ? [{text: 'Other', variant: 'light'}] : [])
				]
			}
		}
	},



	WorkflowAction: {
		check: wfa => Object.keys(wfa).includes("action_class"),
		singular: 'workflow_action',
		plural: 'workflow_actions',
		ui: {
			title: "name",
			subtitle: "comments",
			avatar: wfa => { return {
				icon: wfa.avatar
			}},
			details: wfa => {
				return [
					...(wfa.expected_input_entity_classes ? [{icon: 'arrow-up', icon_variant: 'danger', text: wfa.expected_input_entity_classes.join(', ')}] : []),
					...(wfa.expected_output_entity_classes ? [{icon: 'arrow-down', text: wfa.expected_output_entity_classes.join(', ')}] : [])
				]
			}
		}
	}



}

const EntityType = function(object){

	for(const key in Entities){
		if(Entities[key].check(object)) return key
	}
	return null

}



const EntityUI = function(entity){

	let entity_ui = (Entities[entity._entity_type] && Entities[entity._entity_type].ui) || {}
	let ui = {}

	for(const key in entity_ui){
		if(['avatar'].includes(key)) continue

		if(typeof(entity_ui[key]) == 'function'){
			ui[key] = entity_ui[key](entity)
		}
		if(typeof(entity_ui[key]) == 'string'){
			ui[key] = Object.keys(entity).includes(entity_ui[key]) ? entity[entity_ui[key]] : entity_ui[key]
		}
	}

	return {...ui, ...{avatar: EntityAvatar(entity)}}
}


// EntityAvatar(entity) handles merging the data from entities.ui.avatar

const EntityAvatar = function(entity){

	let entity_avatar = (Entities[entity._entity_type] && Entities[entity._entity_type].ui && Entities[entity._entity_type].ui.avatar) || {}
	let avatar = {}

	for(const key in entity_avatar){
		if(typeof(entity_avatar[key]) == 'function'){
			avatar[key] = entity_avatar[key](entity)
		}
		if(typeof(entity_avatar[key]) == 'string'){
			avatar[key] = Object.keys(entity).includes(entity_avatar[key]) ? entity[entity_avatar[key]] : entity_avatar[key]
		}
		if(typeof(entity_avatar[key]) == 'boolean'){
			avatar[key] = entity_avatar[key]
		}
	}

	return avatar

}


const Entity = function(object, options_to_merge_into_entity={}){

	if(typeof(object)=='string') return object 						// For unconstrained typeaheads we want to return the string
	let {ui, ...options} = options_to_merge_into_entity 			// This lets us pass an object like {new_entity_key: 'something', ui: {new_ui_key: 'something'}} and handle deep merging
	if(Object.keys(object).includes('_entity_type')){				// If this entity hhas already been processed, just return it with the new options merged
		return {...object, ...options, ui: {...object.ui || {}, ...ui}}	
	} 
	let entity_type = EntityType(object)
	let value = {...object, ...{_entity_type: entity_type, _strict_entity_type: entity_type}}

	if(entity_type){
		let entity = {...EntityDefaults, ...Entities[entity_type]}

		for(const key in entity){
			if(['check','ui'].includes(key)) continue	// Skip known, reserved keys

			if(typeof(entity[key]) == 'function'){
				value[key] = entity[key](value)
			}
			if(typeof(entity[key]) == 'string'){
				value[key] = Object.keys(object).includes(entity[key]) ? object[entity[key]] : entity[key]
			}
		}
	}

	return {...value, ...options, ...{ui: {...EntityUI(value), ...ui}}}

}

const FindEntityKey = function(q){
	return Object.keys(Entities).find(entity_key => {
		return entity_key == q || Entities[entity_key].plural == q || Entities[entity_key].singular == q || (!Entities[entity_key].singular && entity_key.toLowerCase() == q) || (!Entities[entity_key].plural && `${entity_key.toLowerCase()}s` == q)
	})
}

const FindEntity = function(q){
	let key = FindEntityKey(q)
	return key ? Entities[key] : undefined
}

const Pluralize = function(q){
	let key = FindEntityKey(q)
	return key ? Entities[key].plural || `${key.toLowerCase()}s` : undefined
}


const EntityEqual = function(entityA, entityB){
	return entityA._strict_entity_type && entityB._strict_entity_type && entityA.id && entityB.id && entityA._strict_entity_type == entityB._strict_entity_type && entityA.id == entityB.id
}



const Mixin = {
	props: {
		value: Object, // Expecting an object to convert to an entity
	},
	computed: {
		entity(){
			if(!this.value) return {ui: {}}
			return Object.keys(this.value).includes('_entity_type') ? this.value : Entity(this.value) // Don't convert the value to an entity again if already done
		}
	}
}


export {Entities, EntityType, Entity, FindEntity, FindEntityKey, Pluralize, EntityEqual, Mixin}
export default Mixin