//Global plugins
//import { BootstrapVue } from 'bootstrap-vue'
//import StayExtensions from './stay-extensions'

// Global Bootstrap Stuff
import { TooltipPlugin } from 'bootstrap-vue'
import { LayoutPlugin } from 'bootstrap-vue'


// Common functions and components used across the app
// import AlertsComponent from 'common/alerts'
import { BButton } from 'bootstrap-vue'
import { ModalPlugin } from 'bootstrap-vue'
import { BFormInput } from 'bootstrap-vue'

import { Params } from 'functions/browser'

// import StayLogo from '../components/stay_logo'
// import OrgLogo from 'common/org_logo'
// import Alert from '../components/common/alert'
// import StayHeader from '../components/common/stay-header'
// import SectionHead from 'common/section_head'
// import PlayButton from '../components/common/_playbutton'
// import BottomConfirm from '../components/common/bottom_confirm'
// import FileUploader from '../components/common/file_uploader'
// import StayAvatar from '../components/common/stay_avatar'
// import Avatar from 'common/avatar'
// import StaySearch from '../components/common/search'
// import StaySearchOverlay from '../components/common/search_overlay'
// import StayAvatarGroup from '../components/common/stay_avatar_group'
// import StayBadge from '../components/common/badge'
// import IconButton from '../components/common/icon_button'
// import Dot from '../components/common/dot'
// import ClipboardButton from '../components/common/clipboard_button'
// import ConfirmDelete from '../components/common/confirm_delete'
// import Confirm from '../components/common/confirm'
// import FloatingButton from '../components/common/floating_button'
// import PhoneNumberInput from '../components/common/phone_number_input'
// import CollapseArrow from '../components/common/collapse_arrow'
// import ArrayCreator from '../components/common/array_creator'
// import ArraySelector from '../components/common/array_selector'
// import ArrayDropdown from '../components/common/array_dropdown'
// import ArraySlot from '../components/common/array_slot'
// import NoResults from '../components/common/no_results'
// import Scale from '../components/common/scale'
// import SwipeGallery from '../components/common/swipe_gallery'
// import IconSelector from '../components/common/icon_selector'
// import Moveable from '../components/common/moveable'
// import EmptyState from '../components/common/empty_state'
// import PostString from '../components/common/post_string'
// import NoteBox from '../components/common/note_box'
// import Typeahead from '../components/common/typeahead'
// import Pill from '../components/common/pill'
// import Hero from '../components/common/hero'
// import ItemOverlay from '../components/common/item_overlay'
// import TextEdit from '../components/common/text_edit'
// import EntityDetails from 'common/entity_details'
// import StayInput from 'common/stay_input'

// Loader templates
// import GridLoader from '../components/common/loaders/grid'
// import AvatarBlock from '../components/common/loaders/avatar_block'

// External stuff
// import FeatherIcons from 'vendor/feather'
// import axios from 'axios'
// import FlagIcon from 'vue-flag-icon'
// import VueTheMask from 'vue-the-mask'
//import VueTelInput from 'vue-tel-input'
// import Vuelidate from 'vuelidate'
// import Pluralize from 'pluralize'
// import range from 'lodash/range'
// import isEmpty from 'lodash/isEmpty'
import isEqual from "lodash/isEqual"
// import merge from 'lodash/merge'
// import mergeWith from 'lodash/mergeWith'
// import assign from 'lodash/assign'
// import debounce from 'lodash/debounce'
// import set from 'lodash/set'
import isElement from 'lodash/isElement'
// import VueClipboard from 'vue-clipboard2'

import {Entity} from 'entity'

const mobileDeviceCheck = function(){
  var check = false;
  (
    function(a){
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) check = true;
    })(navigator.userAgent||navigator.vendor||window.opera);
  return check;
}

const osCheck = function() {
  var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}


var StayData = {
  loading: false,
  loader: {
    message: '',
    variant: 'dark'
  },
  autosave: {
    save: false,
    data: null,
    last_result: null,
    busy: false,
    timer: null
  },
  job_status: {
    id: null,
    status: null,
    message: null,
    data: null
  }
}

const StayCommon = {
  // The install method is all that needs to exist on the plugin object.
  // It takes the global Vue object as well as user-defined options.
  install: Vue => {
    // Some global Javascript object extensions...
    //

    Object.assign(Array.prototype, {
      first: function(limit=1) { return limit == 1 ? this.slice(0, limit)[0] : this.slice(0, limit) },
      last: function(limit=1) { return limit == 1 ? this.slice(-limit)[0] : this.slice(-limit) },
      add: function(element, first=false) {
        if(!this.some(el => isEqual(el, element))){
          if(first){
            this.unshift(element)
          } else {
            this.push(element)
          }
        }
        return this
      },
      remove: function(element){
        let index = this.findIndex(el => isEqual(el, element))
        if(index == -1 && typeof(element) == 'object') index = this.findIndex(el => element.id == el.id)
        console.log(index)
        if(index >= 0){
          this.splice(index, 1)
        }
        return this
      },
      add_or_remove: function(element){
        let index = this.findIndex(el => isEqual(el, element))
        if(index >= 0){
          this.splice(index, 1)
        } else {
          this.push(element)
        }
        return this
      },
      update: function(element){
        console.log(element)
        let index = this.findIndex(el => element.id == el.id)
        console.log(index)
        if(index >= 0){
          this.splice(index, 1, {...this[index], ...element})
        }
        return this
      }
    })

    //Common components
    // Vue.component('alerts', AlertsComponent)
    Vue.component('b-button', BButton)
    Vue.component('b-form-input', BFormInput)

    // Vue.component('stay-logo', StayLogo)
    // Vue.component('org-logo', OrgLogo)
    // Vue.component('playbutton', PlayButton)
    // Vue.component('stay-header', StayHeader)
    // Vue.component('section-head', SectionHead)
    // Vue.component('file-uploader', FileUploader)
    // Vue.component('stay-avatar', StayAvatar)
    // Vue.component('avatar', Avatar)
    // Vue.component('stay-avatar-group', StayAvatarGroup)
    // Vue.component('stay-search', StaySearch)
    // Vue.component('stay-search-overlay', StaySearchOverlay)
    // Vue.component('stay-badge', StayBadge)
    // Vue.component('dot', Dot)
    // Vue.component('bottom-confirm', BottomConfirm)
    // //Vue.component('icon-button', IconButton)
    // Vue.component('clipboard-button', ClipboardButton)
    // Vue.component('floating-button', FloatingButton)
    // Vue.component('phone-number-input', PhoneNumberInput)
    // Vue.component('collapse-arrow', CollapseArrow)
    // Vue.component('array-creator', ArrayCreator)
    // Vue.component('array-selector', ArraySelector)
    // Vue.component('array-dropdown', ArrayDropdown)
    // Vue.component('array-slot', ArraySlot)
    // Vue.component('no-results', NoResults)
    // Vue.component('stay-scale', Scale)
    // Vue.component('swipe-gallery', SwipeGallery)
    // Vue.component('icon-selector', IconSelector)
    // Vue.component('moveable', Moveable)
    // Vue.component('empty-state', EmptyState)
    // Vue.component('note-box', NoteBox)
    // Vue.component('typeahead', Typeahead)
    // Vue.component('pill', Pill)
    // Vue.component('hero', Hero)
    // Vue.component('item-overlay', ItemOverlay)
    // Vue.component('text-edit', TextEdit)
    Vue.component('xsmall', {
      template: '<span class="xsmall"><slot></slot></span>'
    })
    // Vue.component('stay-input', StayInput)


    //External plugins
    Vue.use(TooltipPlugin)
    Vue.use(LayoutPlugin)
    Vue.use(ModalPlugin)

    // Vue.use(FlagIcon)
    // Vue.use(VueTheMask)
    //Vue.use(VueTelInput)
    // Vue.use(Vuelidate)
    // VueClipboard.config.autoSetContainer = true // add this line
    // Vue.use(VueClipboard)
    // const StayAlertComponent = Vue.extend(Alert)


    // Global functions to add to the Vue prototype:
    //

    const Alerts = Vue.prototype.$alerts = []

    const Alert = Vue.prototype.$alert = {
      danger: msg => Alerts.push(['danger', msg]),
      warn: msg => Alerts.push(['warn', msg]),
      info: msg => Alerts.push(['info', msg]),
      success: msg => Alerts.push(['success', msg])
    }

    document.addEventListener('DOMContentLoaded', () => {

      // Here we define global variables that will not change with page updates.
      // Variables that will change are defined in the global_variables mixin as they are
      // mounted as computed functions on the individual components:

      Vue.prototype.$csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      Vue.prototype.$organization = pageData.organization
      Vue.prototype.organization = pageData.organization
      Vue.prototype.human = pageData.human ? Entity(pageData.human) : null
      Vue.prototype.current_client = pageData.current_client
      Vue.prototype.current_user = pageData.current_user
      Vue.prototype.is_client_page = pageData.current_client ? true : false
      Vue.prototype.is_mobile = mobileDeviceCheck()
      Vue.prototype.os = osCheck()
      Vue.prototype.$admin = pageData.current_user && pageData.current_user.role == "admin"
      if(pageData.current_user){
        const StayCurrentUser = Vue.prototype.$current_user = pageData.current_user
        StayCurrentUser.updateConfiguration = (key, value) => {
          StayHttp.post(`${StayCurrentUser.url}/configurations/${key}`, {
            value: value
          })
        }
      }
      // Mount the alerts instance if it hasn't already been mounted
      // if(!document.stayalert){
      //   document.stayalert = new StayAlertComponent({
      //     propsData: {
      //       initial_alerts: window.alerts || []
      //     }
      //   })
      //   document.stayalert.$mount('#stayAlerts')
      // } else {
      //   console.log("Alerts already mounted")
      // }

    })

    const Root = Vue.prototype.$root
    const Emit = Vue.prototype.$emit
    Vue.prototype.$staydata = StayData
    Vue.prototype.$env = {
      production: !Vue.config.devtools,
      development: Vue.config.devtools
    }
    Vue.prototype.$log = console.log
    Vue.prototype.debug = Params.debug && Params.debug != false ? true : false


    // TODO: Import this from the SCSS directly
    // so I only need to change it in one place
    const StayColors = Vue.prototype.$staycolors = {}
    StayColors.primary = '#007BFF'
    StayColors.secondary = '#ACACAC'
    StayColors.light = '#F5F5F5'
    StayColors.dark = '#30323D'
    StayColors.info = '#089FDA'
    StayColors.success = '#3CB371'
    StayColors.danger = '#BB4430'
    StayColors.warn = '#ffc107'
    StayColors.status = function(status){
      switch(status){
        case "draft":
        case "inactive":
          return "secondary"
          break
        case "new":
        case "active":
          return "success"
          break
        case "failed":
        case "deleted":
          return "danger"
          break
        case "saved":
          return "info"
          break
        default:
          return "secondary"
      }
    }
    StayColors.rgb = {
      primary: (a=1)=>{ return StayColors.hexToRGBA(StayColors.primary, a)},
      secondary: (a=1)=>{ return StayColors.hexToRGBA(StayColors.secondary, a)},
      light: (a=1)=>{ return StayColors.hexToRGBA(StayColors.light, a)},
      dark: (a=1)=>{ return StayColors.hexToRGBA(StayColors.dark, a)},
      info: (a=1)=>{ return StayColors.hexToRGBA(StayColors.info, a)},
      success: (a=1)=>{ return StayColors.hexToRGBA(StayColors.success, a)},
      warn: (a=1)=>{ return StayColors.hexToRGBA(StayColors.warn, a)},
      danger: (a=1)=>{ return StayColors.hexToRGBA(StayColors.danger, a)}
    }
    StayColors.hexToRGBA = (h, a)=>{
      let r = 0, g = 0, b = 0;
      // 3 digits
      if (h.length == 4) {
        r = "0x" + h[1] + h[1];
        g = "0x" + h[2] + h[2];
        b = "0x" + h[3] + h[3];
      // 6 digits
      } else if (h.length == 7) {
        r = "0x" + h[1] + h[2];
        g = "0x" + h[3] + h[4];
        b = "0x" + h[5] + h[6];
      }
      return "rgb("+ +r + "," + +g + "," + +b + "," + +a +")";
    }
    StayColors.lightenDarken = (col, amt)=>{
      var usePound = false;
      if (col[0] == "#") {
          col = col.slice(1);
          usePound = true;
      }
      var num = parseInt(col,16);
      var r = (num >> 16) + amt;
      if (r > 255) r = 255;
      else if  (r < 0) r = 0;
      var b = ((num >> 8) & 0x00FF) + amt;
      if (b > 255) b = 255;
      else if  (b < 0) b = 0;
      var g = (num & 0x0000FF) + amt;
      if (g > 255) g = 255;
      else if (g < 0) g = 0;
      return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
    }
    StayColors.textcolor = (hex_or_rgb)=>{
      var rgb;
      if(hex_or_rgb[0] == '#'){rgb = StayColors.hexToRGBA(hex_or_rgb, 1).replace(/[^\d,]/g, '').split(',');}
      else {rgb = hex_or_rgb.replace(/[^\d,]/g, '').split(',');}
      let brightness = Math.round(((parseInt(rgb[0]) * 299) +
                      (parseInt(rgb[1]) * 587) +
                      (parseInt(rgb[2]) * 114)) / 1000);
      return (brightness > 125) ? StayColors.dark : 'white';
    }

    // Iconography
    // const featherIcons = FeatherIcons
    // Vue.prototype.$stayicons = FeatherIcons // Keeping this temporarily so i don't break anything
    // const StayIcon = Vue.prototype.$stayicon = {}
    // StayIcon.svg = function(icon) {
    //   return featherIcons.icons[icon].toSvg({'stroke-width': 0.75})
    // }
    // StayIcon.alert = featherIcons.icons['alert-circle'].toSvg({'stroke-width': 0.75, 'color': StayColors.danger})
    // StayIcon.success = featherIcons.icons['check'].toSvg({'stroke-width': 0.75, 'color': StayColors.success})
    // StayIcon.boolean = function(value) {
    //   if(value){
    //     return StayIcon.success
    //   } else {
    //     return StayIcon.alert
    //   }
    // }

    // // New icon class because I'm sick of messing this up:
    // Vue.prototype.$icons = (icon=null) => {
    //   if(icon){
    //     return featherIcons.icons[icon].toSvg({'stroke-width': 0.75})
    //   } else {
    //     return {
    //       is_icon: icon =>{
    //         return featherIcons.icons[icon] ? true : false
    //       }
    //     }
    //   }
    // }
    // Vue.component('icon', {
    //   template: '<span v-html="featherIcons.icons[icon].toSvg({\'stroke-width\': 0.75})"></span>',
    //   props: {
    //     icon: String
    //   },
    //   data(){
    //     return {
    //       featherIcons: featherIcons
    //     }
    //   }
    // })



    // Images with cloudinary URLs
    const StayImage = Vue.prototype.$stayimage = {}
    StayImage.hero = function(image) {
      return image.slice(0, image.search("/upload/")+8) + "c_fill,g_auto,h_200,w_600/" + image.slice(image.search("/upload/")+8)
      //https://res.cloudinary.com/stay-app/image/upload/c_fill,g_auto,h_200,w_600/v1569216652/refinedk9/users/1/avatar.jpg
    }


    // Alert functionality
    // The alerts instance is initiated and mounted as part
    // of the pageload listener above
    // const StayAlert = Vue.prototype.$stayalert = {}
    // StayAlert.danger = debounce(function (message) {
    //   document.stayalert.alert('danger', message)
    // })
    // StayAlert.success = debounce(function (message) {
    //   document.stayalert.alert('success', message)
    // })
    // StayAlert.info = debounce(function (message) {
    //   document.stayalert.alert('info', message)
    // })
    // StayAlert.warn = debounce(function (message) {
    //   document.stayalert.alert('warn', message)
    // })
    // StayAlert.clear = debounce(function(){
    //   document.stayalert.clear()
    // })
    


    // Some common modals that we use
    const StayModals = Vue.prototype.$staymodals = {}
    StayModals.delete = function(title, message, confirm, button_text) {
      return new Promise((resolve, reject) => {
        const StayConfirm = Vue.extend(ConfirmDelete)
        var StayModal = new StayConfirm({
          propsData: {
            title: title,
            message: message,
            confirmation: confirm,
            button_text: button_text
          },
          mixins: [{
            mounted() {
              console.log("Delete confirmation requested")
            },
            methods: {
              confirmed() {
                resolve(true)
              },
              canceled() {
                reject(false)
              }
            }
          }]
        })
        StayModal.$mount()
      })
    }

    StayModals.confirm = function(text, options={}){
      return new Promise((resolve, reject) => {
        let StayConfirm = Vue.extend(Confirm)
        var StayModal = new StayConfirm({
          propsData: {
            message: text,
            ...options
          }
        })
        StayModal.$on('confirm', (status) => {
          if(status){
            resolve(true)
          } else {
            resolve(false)
          }
        })
        StayModal.$mount()
      })
      
    }

    StayModals.post_string = function(options={}){
      let NewPostString = Vue.extend(PostString)
      return new Promise((resolve, reject) => {
        var StayModal = new NewPostString({
          propsData: options
        })
        StayModal.$on("input", (msg)=>{
          resolve(msg)
        })
        StayModal.$on("cancel", ()=>{
          reject('canceled')
        })
        StayModal.$mount()
      })

    }

    StayModals.details = function(entity){
      let NewEntityDetails = Vue.extend(EntityDetails)
      return new Promise((resolve, reject) => {
        console.log("Entity Details with entity:")
        console.log(entity)
        var StayModal = new NewEntityDetails({
          propsData: {
            value: entity
          }
        })
        StayModal.$on("input", (msg)=>{
          resolve(msg)
        })
        StayModal.$on("cancel", ()=>{
          reject('canceled')
        })
        StayModal.$mount()
      })
    }





    // Loader functionality
    const StayLoader = Vue.prototype.$stayloader = {};
    StayLoader.enable = function (message, options) { 
      StayData.loader.message = message || null
      StayData.loader.variant = options || 'dark'
      StayData.loading = true
    }
    StayLoader.disable = function () {
      StayData.loading = false
    }
    // This is used primarily by directives to attach loader DIVs to
    // a passed element. They are hidden when they are attached and can be
    // shown using el.style.visibility = 'visible'
    StayLoader.attachToElement = function(el, modifiers={}){
      if(el.tagName == "INPUT"){
        // Inputs don't allow for a div to be absolutely positioned above them
        var container = document.createElement('div')
        container.style.position = 'relative'
        var parent = el.parentElement
        parent.replaceChild(container, el)
        container.appendChild(el)

      }
      el.loader = document.createElement('div')
      if(modifiers['no-overlay']){
        el.loader.classList.add('loader')
      } else {
        el.loader.classList.add('loader')
        el.loader.classList.add('overlay')
      }
      if(modifiers['light']){
        el.loader.classList.add('light')
      }
      el.loader.style.visibility = "hidden"
      el.loader.style.opacity = 0
      if(modifiers.center){
        el.loader.classList.add('centered')
      }
      if(el.tagName == "INPUT"){
        el.parentElement.appendChild(el.loader)
      } else {
        el.appendChild(el.loader)
      }
      

      el.showLoader = function(){
        this.loader.style.visibility = 'visible'
        this.loader.style.opacity = 1
      }

      el.hideLoader = function(){
        this.loader.style.visibility = 'visible'
        this.loader.style.opacity = 0
      }
    }
    Vue.component('stay-loader', {
      name: 'stayLoader',
      data: function() {
        return {
          staydata: StayData
        }
      },
      props: {
        message: String,
        options: {
          variant: {
            type: String,
            default: 'dark'
          }
        }
      },
      template: '<div v-show="staydata.loading" class="stay-loader-dark"><svg viewBox="0 0 800 600"><symbol id="s-text"><text text-anchor="middle" x="50%" y="50%" class="text--line">stay</text></symbol><g class="g-ants"><use xlink:href="#s-text"class="text-copy"></use><use xlink:href="#s-text" class="text-copy"></use><use xlink:href="#s-text" class="text-copy"></use><use xlink:href="#s-text" class="text-copy"></use><use xlink:href="#s-text" class="text-copy"></use></g></svg><div class="stay-loader-text">{{staydata.loader.message}}</div></div>'
    })

    // July 2020 Fix for loader staying active on iOS devices
    // when pressing the back button
    window.addEventListener('pageshow', ()=>{
      StayLoader.disable()
    })


    // In-place content loaders
    // Vue.component('stayloader-grid', GridLoader)
    // Vue.component('stayloader-avatar-block', AvatarBlock)
    
    
    // Animation handling
    // Call this to handle animation with reflow capabilities:
    // https://css-tricks.com/restart-css-animation/
    Vue.prototype.$animate = function(el, animation_class="shake-horizontal"){
      if(!isElement(el)){
        // Sometimes a $ref is passed
        el = el.$el
      }
      el.classList.remove(animation_class)
      void el.offsetWidth
      el.classList.add(animation_class)
    }


    // HTTP requests
    const StayHttp = Vue.prototype.$stayhttp = {}
    StayHttp.csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    StayHttp.default_options = {
      loader: true,
      alert: true,
      alert_message: null,
      max_attempts: 8,
      timeout: 3000
    }
    StayHttp.post = async function (url, data={}, options={}) {
      return StayHttp.request(url, data, options, axios.post) 
    }
    StayHttp.put = function (url, data={}, options={}) {
      return StayHttp.request(url, data, options, axios.put)

    }
    StayHttp.delete = function(url, data={}, options={}) {
      // This one requires the data payload to be enclosed in
      // a data parameter. Some weird rails quirk?
      data = {data: merge(data, {authenticity_token: StayHttp.csrf})}
      return StayHttp.request(url, data, options, axios.delete)

    }
    StayHttp.get = function(url, options={}) {
      return StayHttp.request(url, {}, options, axios.get)

    }
    StayHttp.request = async function(url, data, options, func){
      let opts = StayFunctions.merge_options(StayHttp.default_options, options)
      try {
        //console.log(options)
        //let opts = StayFunctions.merge_options(StayHttp.default_options, options)
        //console.log(opts)
        data = merge(data, {authenticity_token: StayHttp.csrf})
        //console.log(data)
        if(opts.loader){
          StayLoader.enable(options.loader_message)
        }
        let response = await func(url, data)
        if(opts.loader){
          StayLoader.disable()
        }
        if(opts.alert){
          StayAlert.success(response.data.message)
        }
        return response
      } catch(err){
        console.log("StayHTTP error:")
        console.log(err.response)
        // Handle 302 redirects first
        if((err.response.status == 302) && err.response.data.data){
          if(opts.alert && err.response.data.message){
            StayAlert.success(err.response.data.message)
          }
          window.location.href = err.response.data.data
          return err.response
        }
        // Making sure this always disables on an error now
        if(opts.loader || true){
          StayLoader.disable()
        }
        if(opts.alert){
          if(err.response.data.message){
            StayAlert.danger(err.response.data.message)
          } else {
            StayAlert.danger("Something went wrong")
          }
        }
        return err
      }

    }
    StayHttp.delayed = {
      post: async (url, data, options={}) => {
        try {
          let loader = options.loader || true
          let max_attempts = options.max_attempts || 8
          let timeout = options.timeout || 3000
          var status
          if(loader){
            StayLoader.enable()
          }
          let job = await StayHttp.post(url, data, {loader: false, alert: false})
          if(loader){
            StayLoader.enable(job.data.message)
          }
          console.log(job.data.data)
          await StayFunctions.sleep(timeout)
          console.log("Pinging for status")
          for(var i = 0; i<=max_attempts; i++){
            console.log(job.data.data.id)
            status = await StayHttp.delayed.job_status(job.data.data.id)
            if(status.data.status == "complete"){
              break
            }
            if(loader){
              StayLoader.enable(status.data.message)
            }
            await StayFunctions.sleep(timeout)
          }
          return status
        } catch(err){
          console.log(err)
          return false
        } finally {
          StayLoader.disable()
        }
      },
      job_status(job_id){
        return StayHttp.get(`/jobs/${job_id}`, {loader: false, alert: false})
      }
    }
    StayHttp.saveFromElement = async function(el){
      try {
        el.showLoader()
        let response = el.rootKey ? await StayHttp[el.method](el.url, {[el.rootKey]: {[el.name]: el.value}}, {loader: false, alert: false}) : await StayHttp[el.method](el.url, {[el.name]: el.value}, {loader: false, alert: false})
        el.hideLoader()
        el.vnode.context.$emit('input', response.data.data)
        return response
      } catch(e) {
        console.error(e)
        Vue.prototype.$animate(el, el.attention)
        el.hideLoader()
        return false
      }
    }



    // Some common javascript functions we need
    const StayFunctions = Vue.prototype.$stayfunctions = {}
    // StayFunctions.merge = merge
    // StayFunctions.isEmpty = isEmpty
    // StayFunctions.set = set
    // const moment = require('moment')
    // StayFunctions.moment = moment
    // StayFunctions.longdate = function(date) {
    //   return moment(date).format('MMMM Do, YYYY')
    // }
    // StayFunctions.shortdate = function(date) {
    //   return moment(date).format('M/D/YYYY')
    // }
    // StayFunctions.time = function(date) {
    //   return moment(date).format('h:mm a')
    // }
    // StayFunctions.calendar = function(date) {
    //   return moment(date).calendar()
    // }
    // StayFunctions.ago = function(date) {
    //   return moment(date).fromNow()
    // }
    // StayFunctions.ago_or_date_after = function(days, date) {
    //   var then = moment().subtract(days, 'days')
    //   if (then.isAfter(date)) {
    //     return moment(date).format("MMM Do")
    //   } else {
    //     return StayFunctions.ago(date)
    //   }
    // }
    // StayFunctions.afterNow = function(date){
    //   return moment(date).isAfter()
    // }
    // StayFunctions.datetime = function(date){
    //   return moment(date).format('MMMM Do YYYY - h:mm a')
    // }
    // StayFunctions.age = function(birthdate) {
    //   if(birthdate){
    //     var birth = new Date(birthdate)
    //     var today = new Date()
    //     var months = 0
    //     var years = today.getFullYear() - birth.getFullYear();
    //     if(today.getMonth() < birth.getMonth()) {
    //       years--
    //       months = 12-birth.getMonth()+today.getMonth()
    //     }
    //     if(today.getMonth() == birth.getMonth() && today.getDate() < birth.getDate()) {
    //       years--
    //       months = 11
    //     }
    //     if(today.getMonth() > birth.getMonth() && today.getDate() < birth.getDate()) {
    //       months = today.getMonth() - birth.getMonth() - 1
    //     }
    //     if(today.getMonth() > birth.getMonth() && today.getDate() >= birth.getDate()) {
    //       months = today.getMonth() - birth.getMonth()
    //     }
    //     return years + " " + Pluralize('year', years) + ", " + months + " " + Pluralize('month', months)
    //   } else {
    //     return null
    //   }
    // }
    StayFunctions.toUsd = function(value) {
      if(!value) return null
      return '$' + parseFloat(value).toFixed(2)
    }
    // StayFunctions.pluralize = Pluralize
    // StayFunctions.equivalent = function (a, b){
    //   // Create arrays of property names
    //   if ((a==undefined || a==null) || (b==undefined || b==null)){
    //     return a == b
    //   }
    //   var aProps = Object.getOwnPropertyNames(a);
    //   var bProps = Object.getOwnPropertyNames(b);

    //   // If number of properties is different,
    //   // objects are not equivalent
    //   if (aProps.length != bProps.length) {
    //     return false;
    //   }

    //   for (var i = 0; i < aProps.length; i++) {
    //     var propName = aProps[i];

    //     // If values of same property are not equal,
    //     // objects are not equivalent
    //     if (a[propName] !== b[propName]) {
    //       return false;
    //     }
    //   }

    //   // If we made it this far, objects
    //   // are considered equivalent
    //   return true;
    // }

    StayFunctions.deep_find = function(target_object, key){
      //console.log(target_object)
      // convert the object in case it was passed as a vue object
      var object = JSON.parse(JSON.stringify(target_object))
      //console.log(object)
      var result = null
      if(object instanceof Array){
        for(var i=0; i<object.length; i++){
          result = StayFunctions.deep_find(object[i], key)
          if(result){
            break
          }
        }
      } else if(object instanceof Object) {
        for(var prop in object){
          //console.log(`${prop} == ${key} ? ${prop == key}`)
          if(prop == key){
            result = object[prop]
            break
          }
          if(object[prop] instanceof Object || object[prop] instanceof Array){
            result = StayFunctions.deep_find(object[prop], key)
            if(result){
              break
            }
          }
        }
      } else {
        // Skip as we can't kind a key in something that isn't an object or array
        //console.warn(`Can't analyze object of type ${typeof(object)}: ${object}`)
      }
      //console.log(result)
      return result;
    }

    StayFunctions.merge_options = function(default_options, options={}){
      return {...default_options, ...options}
    }

    StayFunctions.isos = function(locales) {
      var response = []
      if(typeof(locales)=="string"){
        response[0] = locales.slice(-2).toLowerCase()
      } else if (typeof(locales)=="object"){
        locales.forEach(el => {
          response.push(el.slice(-2).toLowerCase())
        })
      } else {
        console.log(typeof(locales))
      }
      return response.filter((item, index) => response.indexOf(item) === index)
    }

    StayFunctions.capcase = function(str) {
      return str.replace(
            /\w\S*/g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
      )
    }

    StayFunctions.uuid = function() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      })
    }

    StayFunctions.user_or_prospect = function(user, prospect){
      if(this.user && this.prospect){
        throw "Cannot submit both a prospect and a user"
      } else if(!isEmpty(user)){
        //console.log(`User_or_prospect set to {user: ${JSON.stringify(user)}}`)
        return {user: user}
      } else if(!isEmpty(prospect)){
        //console.log(`User_or_prospect set to {prospect: ${JSON.stringify(prospect)}}`)
        return {prospect: prospect}
      } else {
        throw "No user or prospect provided"
      }
    }

    StayFunctions.user_or_prospect_url = function(user, prospect){
      if(this.user && this.prospect){
        throw "Cannot submit both a prospect and a user"
      } else if(!isEmpty(user) || user){
        return `/users/${user.id || user}`
      } else if(!isEmpty(prospect) || prospect){
        return `/prospects/${prospect.id || prospect}`
      } else {
        throw "No user or prospect provided"
      }
    }

    StayFunctions.delayedPush = function(pageData, data, timeout){
      timeout = timeout || 1000
      setTimeout(function(){ pageData.push(data) }, timeout);
    }

    StayFunctions.delayedUnshift = function(pageData, data, timeout){
      timeout = timeout || 1000
      setTimeout(function(){ pageData.unshift(data) }, timeout);
    }

    StayFunctions.delayedUpdate = function(pageData, data, timeout){
      timeout = timeout || 1000
      setTimeout(function(){ pageData = data }, timeout);
    }

    StayFunctions.delayedUpdateById = function(pageData, data, timeout){
      timeout = timeout || 1000
      setTimeout(function(){
        let index = pageData.findIndex((d) => { return d.id === data.id })
        if(index >= 0){
          Vue.prototype.$set(pageData, index, data)
        } else {
          pageData.unshift(data)
        }
      }, timeout)
    }

    StayFunctions.delayedDeleteById = function(pageData, data, timeout){
      data = (typeof(data) == "number" ? {id: data} : data)
      timeout = timeout || 1000
      setTimeout(function(){
        let index = pageData.findIndex((d) => { return d.id === data.id })
        if(index >= 0){
          pageData.splice(index, 1)
        }
      }, timeout)
    }

    StayFunctions.delayedRefresh = function(timeout){
      timeout = timeout || 1000
      StayLoader.enable()
      setTimeout(function(){ location.reload() }, timeout);
    }

    StayFunctions.childElements = function(elem){
      var children = [];
      var q = [];
      q.push(elem);
      while (q.length > 0) {
        var elem = q.pop();
        children.push(elem);
        pushAll(elem.children);
      }
      function pushAll(elemArray){
        for(var i=0; i < elemArray.length; i++) {
          q.push(elemArray[i]);
        }
      }
      return children;
    }

    StayFunctions.sleep = function(time){
      const ms = time || 5000
      return new Promise(resolve => setTimeout(resolve, ms))
    }

    StayFunctions.download = function(url, extension="pdf"){
      axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `file.${extension}`);
            document.body.appendChild(link);
            link.click();
        })
    }
    StayFunctions.compose = function(options={}){
      return new Promise((resolve, reject)=>{
        let Compose = Vue.extend(MessageComposerModal)
        var Modal = new Compose({
          propsData: options
        })
        Modal.$on("input", (data)=>{
          resolve(data)
        })
        Modal.$on("cancel", ()=>{
          reject("canceled")
        })
        Modal.$mount()
      })

    }

    StayFunctions.upload = function(options={}){
      return new Promise((resolve, reject)=> {
        let FileUploaderModal = Vue.extend(FileUploader)
        console.log("Launching file_uploader with options:")
        console.log(merge(options, {modal: true}))
        var Modal = new FileUploaderModal({
          propsData: merge(options, {modal: true})
        })
        Modal.$on('upload', (data)=>{
          resolve(data)
        })
        Modal.$on('error', (err)=>{
          reject(err)
        })
        Modal.$mount()
      })
    }



    // Some common filters that we can use
    Vue.filter('uppercase', function (value) {
      if(!value) return null
      return value.toUpperCase()
    })

    Vue.filter('capitalize', function (value) {
      if(!value) return null
      var sentence = value.toLowerCase().split(" ");
      for(var i = 0; i< sentence.length; i++){
         sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }
      return sentence.join(" ")
    })

    Vue.filter('titlecase', function (value) {
      if(!value) return null
      var sentence = value.toLowerCase().split(" ");
      for(var i = 0; i< sentence.length; i++){
         sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }
      return sentence.join(" ")
    })

    Vue.filter('ordinal', function(value){
      switch(parseInt(value)){
        case 0:
          return 'zero'
          break
        case 1:
          return 'first'
          break
        case 2:
          return 'second'
          break
        case 3:
          return 'third'
          break
        case 4:
          return 'fourth'
          break
        case 5:
          return 'fifth'
          break
        case 6:
          return 'sixth'
          break
        case 7:
          return 'seventh'
          break
        case 8:
          return 'eighth'
          break
        case 9:
          return 'ninth'
          break
        case 10:
          return 'tenth'
          break
      }
    })

    Vue.filter('truncate', function (value, limit) {
      limit = limit || 20
      if (value) {
        return value.substring(0, limit) + (value.length > limit ? "..." : "")
      } else {
        return ""
      }
    })

    Vue.filter('usd', StayFunctions.toUsd)

    Vue.filter('as_icon', function (value) {
      if(value == "true" || (value && typeof value === "boolean")) {
        return StayIcon.svg('x')
      } else {
        return
      }
    })

    Vue.filter('pretty', function(value){
      return JSON.stringify(value, null, 2);
    })

    Vue.filter('round', function(value, precision=0){
      return Number.parseFloat(value).toFixed(precision)
    })

    Vue.filter('address', function(value){
      if(value){
        return `${(value.street ? `${value.street}\r\n` : '')}${value.city}${((value.state && value.city) ? ', ' : ' ')}${value.state} ${value.zip}`
      } else {
        return null
      }
    })

    Vue.filter('breed', function(value){
      if(value.breeds && value.breeds.length > 0){
        return value.breeds.map((b) => {return b.name}).join(", ")
      } else {
        return value.breed_input
      }
    })



    // DIRECTIVES
    Vue.directive('loading', {
      bind(el, binding){
        // Nothing to do
      },
      inserted(el, binding){
        StayLoader.attachToElement(el, binding.modifiers)
        if(binding.value){
          el.showLoader()
        }
      },
      update(el, binding){
        if(binding.value){
          el.showLoader()
        } else {
          // Not sure why we had the setTimeout here but moving to the new
          // abstracted version of this:
          el.hideLoader()
          //el.loader.style.opacity = 0
          //setTimeout(()=>{el.loader.style.visibility = "hidden"}, 250)
        }
      }
    })


    Vue.directive('entrance', {
      bind(el, binding){
        el.entrance = binding.value || "swing-in-top-fwd"
        el.visible = (el.style.visibility == "hidden" ? false : true)
      },
      update(el, binding){
        var visible = (el.style.visibility == "hidden" ? false : true)
        if(visible && (visible != el.visible)){
          el.visible = visible
          el.classList.add(el.entrance)
          setTimeout(()=>{
            el.classList.remove(el.entrance)
          }, 500)
        }
      }
    })

    Vue.directive('exit', {
      bind(el, binding){
        el.exit = binding.value || "swing-out-top-bck"
        el.visible = (el.style.visibility == "hidden" ? false : true)
      },
      update(el, binding){
        var visible = (el.style.visibility == "hidden" ? false : true)
        if(!visible && (visible != el.visible)){
          el.style.visibility = "visible"
          el.visible = visible
          el.classList.add(el.exit)
          setTimeout(()=>{
            el.classList.remove(el.exit)
            el.style.visibility = "hidden"
          }, 500)
        }
      }
    })

    // Similar to the entrance / exit binding, this one expects 2 different animations (entrance and exit) as modifiers
    // and a binding value that results in a true / false
    Vue.directive('visible', {
      bind(el, binding){
        el.visible_entrance = Object.keys(binding.modifiers).find((k)=>{ return k.includes('-in') }) || "swing-in-top-fwd"
        el.visible_exit = Object.keys(binding.modifiers).find((k)=>{ return k.includes('-out') }) || "swing-out-top-bck"
        el.original_position = el.style.position
      },
      inserted(el, binding){
        if(!binding.value){
          el.style.visibility = "hidden"
          el.style.position = "absolute"
        }
      },
      update(el, binding){
        if(!isEqual(binding.oldValue, binding.value) && (binding.oldValue != undefined)){
          if(binding.value){
            // First remove the exit if it is there
            el.classList.remove(el.visible_exit)
            el.style.position = el.original_position
            Vue.prototype.$animate(el, el.visible_entrance)
          } else {
            // First remove the entrance if it is there
            el.classList.remove(el.visible_entrance)
            Vue.prototype.$animate(el, el.visible_exit)
            setTimeout(()=>{el.style.position="absolute"}, 1000)
          }
        }
      }
    })


    // Typeahead directive turns any element into a typeahead
    // search capable element. Modifiers include:
    // 1. no-results (hide the built-in results layer)
    Vue.directive('typeahead', {
      bind(el, binding){
        var ResultsComponent = Vue.extend(TypeaheadResults)
        el.timeout = 1000
        el.url = binding.value || "/search"
        el.loading = false
        el.data = []
        el.old_value = ""
        el.results_component = new ResultsComponent({
          propsData: {
            results: el.data
          }
        })

      },
      inserted(el, binding){
        // We need to create a new element to attach the loading
        // behaviors to
        let current_parent = el.parentNode
        let new_parent = document.createElement('div')
        el.results = document.createElement('div')
        el.results.setAttribute("id", "typeahead-results");
        current_parent.replaceChild(new_parent, el)
        new_parent.appendChild(el)
        new_parent.appendChild(el.results)
        el.results_component.$mount('#typeahead-results')
        new_parent.classList.add('stay-typeahead')
      },
      componentUpdated(el, binding, vnode){
        console.log(vnode)
        if(!el.loading && (el.value != el.old_value)){
          el.old_value = el.value
          el.loading = true
          setTimeout(()=>{Vue.prototype.$stayhttp.post(el.url, {search: {q: el.value}}, {alert: false, loader: false}).then(response=>{
            console.log(response)
            el.data = (response.data.data ? response.data.data : response.data)
            el.results_component.internal_results = el.data.results
            if (vnode.componentInstance){
              vnode.child.$emit('typeahead', el.data)
            } else {
              vnode.elm.dispatchEvent(new CustomEvent('typeahead', {detail: el.data}))
              //vnode.elm.dispatchEvent('typeahead', el.data)
            }
          }, 
            err=>{
              console.log(err)
            })}, el.timeout)
          setTimeout(()=>{el.loading = false}, el.timeout)
        }
      }
    })

    Vue.directive('autosave', {
      bind(el, binding, vnode) {
        //console.log(vnode)
      },
      update(el, binding, vnode) {
        const timeout = binding.value.timeout || 10000
        if(!StayData.autosave.save && (StayData.autosave.data != el.value) && (el.value != "")) {
          console.log(`Autosave URL is ${binding.value.url} using method ${binding.arg}`)
          console.log("Data will autosave in " + (timeout/1000).toString() + " seconds...")
          StayData.autosave.save = true
          StayData.autosave.timer = setTimeout(() => {
            StayData.autosave.busy = true
            console.log("Saving...")
            //PUT binding
            if(binding.arg=="put") {
              StayHttp.put((binding.value.url || "/autosave"), {
                ...binding.value, 
                [el.id]: el.value
              }).then(response => {StayData.autosave.last_result = response.data.data.updated_at}, err => {StayData.autosave.last_result = "error"})
            }
            //POST binding
            if(binding.arg=="post") {
              StayHttp.post((binding.value.url || "/autosave"), {
                ...binding.value, 
                [el.id]: el.value
              }, {
                alert: false,
                loader: false
              }).then(response => {StayData.autosave.last_result = response.data.data}, err => {StayData.autosave.last_result = "error"})
            }
            // GET binding
            if(binding.arg=="get") {
              StayHttp.get((binding.value.url || "/autosave"), {loader: false, alert: false}).then(response => {StayData.autosave.last_result = response.data.data}, err => {StayData.autosave.last_result = "error"})
            }
            StayData.autosave.save = false
            StayData.autosave.busy = false
          }, timeout)
        }
        StayData.autosave.data = el.value

      }
    })
    Vue.prototype.$stayautosave = StayData.autosave

    // This allows us to edit strings in place and send them to the server
    // immediately inline
    Vue.directive('save', {
      bind(el, binding, vnode){
        if(!el.name){
          console.error("v-save requires the element to have a name")
        }
        if(!binding.value){
          console.error("v-save requires the update route as the binding value")
        } else {
          el.url = binding.value
        }
        if(binding.modifiers.post){
          el.method = "post"
        } else if(binding.modifiers.get){
          el.method = "get"
        } else {
          el.method = "put"
        }
        el.attention = el.attention || 'shake-horizontal'
        el.vnode = vnode
        if(el.dataset.rootKey){
          console.log(`v-save element found with root key: ${el.dataset.rootKey}`)
          el.rootKey = el.dataset.rootKey
        }
      },
      inserted(el, binding, vnode){
        StayLoader.attachToElement(el, binding.modifiers)
        el.addEventListener('change', function(e){
          StayHttp.saveFromElement(this)
        })
      }
    })


    Vue.directive('focus', {
      // When the bound element is inserted into the DOM...
      inserted: function (el) {
        // Focus the element
        el.focus()
      }
    })

    Vue.directive('textcolor', {
      inserted: (el, binding, vnode)=>{
        let bgColor = el.parentElement.style.backgroundColor // This requires the text block to be a direct child of the bg color el
        el.color = StayColors.textcolor(bgColor)
      },
      updated: (el, binding, vnode)=>{
        let bgColor = el.parentElement.style.backgroundColor // This requires the text block to be a direct child of the bg color el
        el.color = StayColors.textcolor(bgColor)
      }
    })


  }
};

export { StayData, StayCommon };
