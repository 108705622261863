<template>
		<div class="phone-number-input">
			<icon-button :icon="icons[user_phone.label]" show-carat dropright :tooltip="user_phone.label | titlecase" hover>
				<template>
					<b-dropdown-item @click="handleLabelUpdate('home')">Home</b-dropdown-item>
					<b-dropdown-item @click="handleLabelUpdate('mobile')">Mobile</b-dropdown-item>
					<b-dropdown-item @click="handleLabelUpdate('work')">Work</b-dropdown-item>
				</template>
			</icon-button>
			<vue-tel-input
				:onlyCountries="$stayfunctions.isos(locales)"
				v-model="phone_number"
				:inputClasses="{ 'is-invalid': inValid }"
				:wrapperClasses="{ 'is-invalid': inValid }"
				:dropdown-options="{
					showDialCodeInSelection: true,
					showFlags: false
				}"
				:enabledFlags="false"
				enabled-country-code
				ref="vue-tel-input"
				:placeholder="placeholder"
				@validate="handleValidate"
				@country-changed="handleInput"
				@input="handleInput"
				@blur="$emit('blur')"
				:label="user_phone.label"
				@enter="$emit('keyup', new keyboard_event('keyup', {
    				code: 'Enter',
    				key: 'Enter',
					charCode: 13,
					keyCode: 13,
				}))"
			>
				<template v-slot:arrow-icon>
					<icon value='arrow-down' />
				</template>
			</vue-tel-input>
	</div>
</template>

<script>
	// This component should handle creation and editing of a UserPhone object that the server will recognize
	// natively. The expected schema looks like the data model below
	// We're currently using vue-tel-input: https://educationlink.github.io/vue-tel-input/
	// tel is the vue-tel-input component and vm is the component
	const PhoneMap = function(tel, vm){
		return {
			phone_number: tel.phoneObject.number.e164,
			friendly_name: tel.phoneText,
			iso: tel.phoneObject.country.iso2,
			valid: tel.phoneObject.isValid
		}
	}

	import { VueTelInput } from 'vue-tel-input'
	import IconButton from 'common/icon_button'
	import Icon from 'common/icon'
	import { BDropdownItem } from 'bootstrap-vue'

	export default {
		data() {
			return {
				phone_number: null, // This is the internal_phone number and will show the model defined by vue-tel-input
				show_invalid: false,
				user_phone: {
					phone_number: null,
					friendly_name: null,
					label: "home",
					capabilities: {
						sms: null,
						mms: null,
						voice: null
					},
					iso: null,
					sms_opt_in: false,
					verified: false,
					default: false,
					valid: false
				},
				icons: {
					home: 'home',
					mobile: 'smartphone',
					work: 'briefcase'
				},
				mounted: false,
				valid: false,
				keyboard_event: window.KeyboardEvent
			}
		},
		props: {
			// Which countries to show in the dropdown
			locales: {
				type: String | Array,
				default: ()=>{return ['en-US','en-CA']}
			},
			placeholder: String,
			value: Object | String,
		},
		methods: {
			handleValidate(evt) {
				this.valid = evt.isValid
				// Only emit after the initial validation
				// has been completed:
				if(this.mounted){
					this.$emit('valid', this.valid)
				} else {
					this.mounted = true
				}
			},
			handleInput() {
				if(this.mounted){
					//console.log(PhoneMap(this.$refs['vue-tel-input'], this))
					this.mergeValue(PhoneMap(this.$refs['vue-tel-input']))
					this.$emit('input', this.user_phone)
				} else {
					this.mounted = true
				}
			},
			handleLabelUpdate(label){
				this.user_phone.label = label
				this.$emit('input', this.user_phone)
			},
			mergeValue(val){
				Object.keys(val).forEach((k)=>{
					this.user_phone[k] = val[k]
				})
				return this.user_phone
			}
		},
		computed: {
			inValid(){
				return this.show_invalid && !this.$refs['vue-tel-input'].valid
			}
		},
		mounted(){
			// We want to wait until the vue-tel-input
			// has a chance to validate the initial value before
			// we set the component mounting state to true
			// Likewise we are passing setting of the internal values
			// to a watcher to handle v-model being set after mounting
			//console.log(`phone_number_input: MOUNTING WITH VALUE: ${JSON.stringify(this.value)}`)
			if(typeof(this.value)=="string"){
				this.user_phone.friendly_name = this.value
				this.phone_number = this.value
			} else if(this.value == null){
				
			} else {
				this.mergeValue(this.value)
				this.phone_number = this.value.friendly_name
			}
		},
		components: {
			'vue-tel-input': VueTelInput,
			Icon, IconButton, BDropdownItem
		},
		//mixins: [Dirty]
	}
</script>

<style lang="scss">
	@import './shared';
	.phone-number-input{
		position: relative;
		border: 1px solid #ced4da;
		width: 100%;
		display: flex;
	}
	.vue-tel-input {
		border: none;
		width: 100%;
		width: 100%;

		input {
			height: calc(1.5em + 0.75rem + 2px);
	    	padding: 0.375rem 0.75rem;
	    	font-size: 1rem;
	    	font-weight: 400;
	    	line-height: 1.5;
		}

		.vti__selection .vti__country-code {
			font-size: 1rem;
	    	font-weight: 400;
	    	font-color: black;
		}

		svg {
			width: 0.75rem;
			height: 0.75rem;
		}
	}
	.vue-tel-input.is-invalid {
		border-color: $danger-color;
	}
</style>