// Common AJAX functions leveraging Axios
const DefaultOptions = {
	loader: true,
	alert: true,
	alert_message: null,
	max_attempts: 8,
	timeout: 3000,
	events: true
}
const Csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

import Axios from 'axios'
import merge from 'lodash/merge'
export default {
	methods: {
		post(url, data={}, options={}) {
			return this.request(url, data, options, Axios.post) 
		},

		get(url, data={}, options={}) {
			// GET data payloads are required to be wrapped in a
			// params object when passed to Axios:
			data = {params: data}
			return this.request(url, data, options, Axios.get) 
		},

		put(url, data={}, options={}) {
			return this.request(url, data, options, Axios.put) 
		},

		delete(url, data={}, options={}) {
			// This one requires the data payload to be enclosed in
			// a data parameter. Some weird rails quirk?
			data = {data: {...data, ...{authenticity_token: Csrf}}}
			return this.request(url, data, options, Axios.delete)
		},

		async download(url, filename='file', options={}) {
			let opts = {...DefaultOptions, ...options}
			try{
				let extension = url.split('.').last()
				filename = filename.replace(`.${extension}`, '')
				if(this.loading != undefined){ this.loading = true }
				if(opts.loader){ this.$stayloader.enable(options.loader_message) }
				let response = await Axios({url: url, method: 'GET', responseType: 'blob'})
				if(this.loading != undefined){ this.loading = false }
				if(opts.loader){ this.$stayloader.disable() }
				if(response.status != 200) throw(new Error(`Non-200 response received from download request: ${response.data && response.data.message}`))
				url = window.URL.createObjectURL(new Blob([response.data]))
				let link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `${filename}.${extension}`)
				document.body.appendChild(link)
				link.click()
			} catch(err){
				throw(err)
			}
		},

		async request(url, data, options, func){
			let opts = {...DefaultOptions, ...options}
			try {
				if(!url) throw("No URL was provided to the HTTP mixin request!")
				data = {...data, ...{authenticity_token: Csrf}}
				if(this.loading != undefined){ this.loading = true }
				if(opts.loader){ this.$stayloader.enable(options.loader_message) }
				let response = await func(url, data)
				if(this.loading != undefined){ this.loading = false }
				if(opts.loader){ this.$stayloader.disable() }
				if(opts.alert){ this.$alert.success(response.data.message) }
				if(this.$root){ this.$root.$emit("http:response", response.data) }


				// Here we will handle any EMITs that need to occur. We look for keys in the
				// response that are not part of :message or :data (e.g. :pagination, :search...)
				// for(let response_key in response.data){
				// 	console.log(response_key)
				// 	if(['message','data'].includes(response_key)) continue
				// 	for(let instance_key in response.data[response_key]){
				// 		this.$root.$emit(`${response_key}:${instance_key}:update`, response.data[response_key][instance_key])
				// 	}
				// }
				// Moved to the events.js mixin...
				if(this.$handleHttpResponse && opts.events){
					this.$handleHttpResponse(response)
				} else if(this.$root.$handleHttpResponse && opts.events){
					this.$root.$handleHttpResponse(response)
				}

				// if(response.data.pagination){
				// 	if(Object.keys(response.data.pagination).includes("pages")){ 
				// 		console.warn("Pagination response received without keys. Pagination components specifying a key won't update!")
				// 		this.$root.$emit("pagination:global:updated", response.data.pagination)
				// 	} else {
				// 		Object.keys(response.data.pagination).forEach((r)=>{
				// 			this.$root.$emit(`pagination:${r}:update`, response.data.pagination[r])
				// 		})
				// 	}
				// }
				return response
			} catch(err){
				console.log(err)
				// Handle 302 redirects first
				console.log(err.response.status)
				if((err.response.status == 302) && (err.response.data.data || err.response.data.url)){
					console.log("Redirect returned, redirecting the page...")
					if(opts.alert && err.response.data.message){ this.$alert.success(err.response.data.message) }
					window.location.href = err.response.data.data || err.response.data.url
					return err.response
				}
				// If the authentication has expired we will get a 401.
				// We should redirect to the sign in page in this case...
				if(err.response.status == 401){
					window.location.href = "/user/login"
				}
				console.error("StayHTTP error:")
				console.log(err)
				console.error(err.response || err)
				if(this.loading != undefined){ this.loading = false }
				if(opts.loader || true){ this.$stayloader.disable() }
				if(opts.alert){
					if(err.response.data.message){ this.$alert.danger(err.response.data.message) }
				} else {
					this.$alert.danger("Something went wrong")
				}
				throw(err)
			}
		}
	},
	created(){
		// This is to make all the existing components backwards-compatible
		// TODO: Remove this after we have updated all of the componentry
		this.$stayhttp = {
			post: this.post,
			put: this.put,
			// We added support for data params in the GET call so this is an abstraction layer:
			get: (url, options={})=>{ return this.get(url, {}, options) },
			delete: this.delete
		}
	}
}