<template>
	<div class="icon-button" v-b-tooltip.hover="tooltip" v-loading.center="!circle && loading">

		<!-- COUNT -->
		<div class="count" v-if="count">
			{{count}}
		</div>



		<!-- PLAIN ICON BUTTON -->
		<button v-if="!$slots.default" 
			@click="handleClick" 
			:disabled="disabled" 
			:class="{
				'hover': hover,
				'flush': flush,
				'pill': pill,
				'circle': circle,
				'disabled': disabled,
				'card': card,
				'selected': selected,
				'border': border,
				[variant]: true,
				[size]: true
			}"
		>
			<icon :value="icon" :framework="framework" :disabled="disabled" />{{title}}
		</button>


		<!-- DROPDOWN ICON BUTTON -->
		<b-dropdown v-if="$slots.default && !card" 
			ref="dropdown"
			:no-caret="!showCaret" 
			right 
			:dropup="dropup" 
			:dropright="dropright" 
			@show="$emit('show')" 
			@hide="$emit('hide')" 
			:text="title" 
			:tabindex="tabindex.toString()" 
			:disabled="disabled"
			:menu-class="{
				...{
					'icon-button-menu': true
				},
				...menuClass
			}"
			:toggle-class="{
				//'bg-transparent': true,
				'hover': hover,
				'flush': flush,
				'pill': pill,
				'circle': circle,
				'disabled': disabled,
				'card': card,
				'selected': selected,
				'border': border,
				[variant]: true,
				[size]: true
			}"
		>
			<template v-slot:button-content>
				<icon :value="icon" :framework="framework" :disabled="disabled" />{{title}}
			</template>
			<template #default="{hide}">
				<slot :hide="hide"></slot>
			</template>
		</b-dropdown>

		<transition name="fade">
			<div class="icon-button-circle-loader" v-if="circle && (loading || internal_loading)"></div>
		</transition>
	</div>
</template>

<script>
	import {BDropdown, BBadge} from 'bootstrap-vue'
	import Icon from 'common/icon'
	export default {
		components: {
			Icon, BDropdown
		},
		name: 'icon-button',
		data() {
			return {
				internal_loading: false
			}
		},
		props: {
			icon: String,			// The icon to use
			framework: String, 		// Force the icon framework
			card: Boolean,			// Display as a card rather than a button
			tooltip: String,
			title: String,			// Text to be included with the icon
			variant: {
				type: String,
				default: 'dark'
			},
			disabled: Boolean,
			selected: Boolean,
			size: {
				type: String,
				default: "md"
			},
			count: Number, 			// If you want a small number in the top right corner
			dropdown: Boolean,		// These can be used
			dropup: Boolean,		// interchangeably
			dropright: Boolean,
			flush: Boolean,
			hover: Boolean,
			href: String,
			newWindow: Boolean,
			background: Boolean,
			pill: Boolean,
			border: Boolean,
			circle: Boolean,
			showCaret: Boolean,
			loading: Boolean,
			tabindex: {
				type: String | Number,
				default: '0'
			},
			menuClass: {
				type: Object,
				default: ()=>{return {}}
			}
		},
		methods: {
			handleClick(){
				this.$emit('click')
				if(this.href){
					if(this.newWindow){
						window.open(this.href)
					} else {
						window.location = this.href
					}
				}
			}
		}
	}
</script>

<style lang="scss">
	@import 'stylesheets/colors';
	@import 'stylesheets/variables';
	@import 'stylesheets/sizes';

	.icon-button {
		position: relative;
		display: inline-block;

		button:not(.dropdown-item) {
			border: none;
			outline: none;
			background-color: Transparent;
			max-height: 100%;
			padding: 0.5em;
			box-shadow: none !important;
			@include disabled;
			@include font-sizes;
			@include colors;

			&.hover { @include hover; }
			&.flush { padding: 0; }
			&.selected { @include selected; }
			&.card { @include card; }
			&.pill { @include pill; }


			&.border { 
				@include border-colors;
				border-width: 1px;
				border-style: solid;
			}

			&.circle {
				@include card;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 200px;
				width: 1.5em;
				height: 1.5em;
				padding: 0;

				.icon {
					font-size: 0.8em;
				}
			}
		}

		.count {
			position: absolute;
			top: 0;
			right: 0;
			font-size: 0.5rem;
			background-color: $warn-color;
			color: $white-color;
			width: 0.75rem;
			height: 0.75rem;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0.1rem;
		}

		.icon-button-circle-loader{
			display: flex;
			position: absolute;
			pointer-events: none;
			align-items: center;
			justify-content: flex-end;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 2;

			&::after {
				content: "";
				border: 2px solid white;
				border-radius: 50%;
				border-top: 2px solid $info-color;
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,0.3);
				-webkit-animation: spin 2s linear infinite; /* Safari */
				animation: spin 1s linear infinite;
			}
		}

		@-webkit-keyframes spin {
			0% { -webkit-transform: rotate(0deg); }
			100% { -webkit-transform: rotate(360deg); }
		}

		@keyframes spin {
			0% { transform: rotate(0deg); }
			100% { transform: rotate(360deg); }
		}


	}
</style>