<template>
	<b-modal id="sign-up" size="lg" :visible="visible" hide-footer>
		<div class="modal-content-landing">
			<form method="post" action="/signup">
				Hi &#128075, my email address is
				<input type="text" name="lead[email]" placeholder="email">
				and I am a(n)
				<select name="lead[role]">
					<option value="Animal Behaviorist">Animal Behaviorist</option>
					<option value="Kennel Owner">Kennel Owner</option>
					<option value="Animal Trainer">Animal Trainer</option>
					<option value="Vet Technician">Vet Technician</option>
					<option value="Animal Enthusiast">Animal Enthusiast</option>
				</select>
				<input type="hidden" name="authenticity_token" :value="$root.csrf">
				<input type="submit" name="login" value="Keep me posted">
				<small>We promise to only use your email address to send you occaasional updates on our progress and maybe ask you a few questions about the types of features you would like to see. We do not sell or provide your info to any third parties (we promise).</small>
			</form>
		</div>
	</b-modal>
</template>

<script>
	import { BModal } from 'bootstrap-vue'
	export default {
		name: 'sign-up',
		props: {
			visible: Boolean
		},
		methods: {
			hideUpdates: function() {
				this.$root.updates = false;
			}
		},
		components: {'b-modal': BModal}
	}


</script>

<style lang="scss">
	.modal-content-landing {
		font-size: 1.5em;
	}
</style>